import {clickEvent, customDocumentEvent, documentLoadEvent, mouseDownEvent} from "../../../../webpack/modules/events";
import {getFilters, initCombinationSuggestions, updateImageDisplay} from "../../../../webpack/modules-optimized/product-list";
import {ga4TrackSimpleEvent, itemDatalayerPush} from "../../../../webpack/modules/analytics";
import {initLastViewedProductsRecommender} from "../../../../webpack/modules-optimized/last-viewed-products";
import {get} from "../../../../webpack/modules/ajax";
import {checkElementIsVisibleInViewport, onVisible} from "../../../../scripts/Tools/visible-element-observer";
import {isDesktop, isMobile, isTablet} from "../../../../webpack/modules/devices";
import {saveLastSearch} from "../../../../webpack/modules/last-searches";

const listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';

documentLoadEvent(function() {
    initCombinationSuggestions(document.querySelector('.combination-suggestions'));
    saveCurrentSearch();
});

let renderLastViewedProducts = false;
if (!renderLastViewedProducts) {
    let container = document.querySelector('#last-viewed-products-container');
    if (container) {
        initLastViewedProductsRecommender(container);
        renderLastViewedProducts = true;
    }
}

if (isMobile() || isTablet()) {
    updateUrl();
    addProductsEvents();
}

clickEvent('#product-list-page .last-item-viewed', true, function (item) {
    let reference = item.dataset.ecRef || '';
    ga4TrackSimpleEvent({
        'event': listType,
        'method': 'last_visited_products',
        'content_type': reference
    });
});

mouseDownEvent('#indexed-search a', false, function (item) {
    ga4TrackSimpleEvent({
        'event': listType,
        'method': 'related_search',
        'content_type': item.getAttribute('title') ? item.getAttribute('title') : null
    });
});

clickEvent('.btn-page', true, async function(element) {
    let pageToGo = element.dataset.page;
    let currentPage = parseInt(document.querySelector('#current-page').value);
    let page = document.querySelector(`#product-list-page .products-block[data-page="${pageToGo}"]`);

    if (!page) {
        await addProducts(pageToGo, pageToGo < currentPage ? 'prepend' : 'append', pageToGo < currentPage);
    }

    document.querySelector(`#product-list-page .products-block[data-page="${pageToGo}"]`).scrollIntoView({ behavior: 'smooth', block: 'start', inline: "start"});
});

customDocumentEvent('productListUpdated', function(event) {
    let isLastPage = event.detail.isLastPage;
    renderLastViewedProducts = false;
    if (isMobile() || isTablet()) {
        if (isLastPage) {
            document.querySelector('footer').classList.remove('d-none');
            document.querySelector('.product-services-banner').classList.remove('d-none');
        } else {
            document.querySelector('footer').classList.add('d-none');
            document.querySelector('.product-services-banner').classList.add('d-none');
        }
    }
});

function addProductsEvents()
{
    document.querySelectorAll('#product-list-page .render-next-page').forEach(function (element) {
        if (checkElementIsVisibleInViewport(element)) {
            let currentPage = parseInt(document.querySelector('#current-page').value);

            element.classList.remove('render-next-page');
            addProducts(currentPage + 1);
        }
    });
}

let isLastPage = false;
let ajaxAddProducts = null;
function addProducts(page, method = 'append', scrollToLoader = false)
{
    return new Promise(resolve => {
        if (isLastPage) return;
        if (isDesktop()) return;
        if (document.querySelector(`#product-list-page .products-block[data-page="${page}"]`)) return;

        let element = document.querySelector('#product-list-page #list-products');
        let type = document.querySelector('#type').value;
        let filters = JSON.stringify(getFilters(document.querySelector('#product-list-page')));
        let query = document.querySelector('#query-string').value;
        let url = location.href;
        let imageDisplay = document.querySelector('#product-list-page .image-display-selector:checked').value;

        addPaginatorLoader(method);
        if (scrollToLoader) document.querySelector(`#product-list-page .paginator-loader`).scrollIntoView({ behavior: 'smooth', block: 'center', inline: "start"});
        if (ajaxAddProducts) ajaxAddProducts.abort();
        ajaxAddProducts = get(
            '/ajax/website/product-list/add-results',
            {
                type: type,
                filters: filters,
                page: page,
                q: query,
                url: btoa(url)
            },
            function (data) {
                removePaginatorLoader();
                if (method === 'append') {
                    element.insertAdjacentHTML('beforeend', data.productsView);
                } else {
                    element.insertAdjacentHTML('afterbegin', data.productsView);
                    if (page === "1") {
                        let lastViewedProductsContainer = document.querySelector('#last-viewed-products-container');
                        if (lastViewedProductsContainer && !renderLastViewedProducts) {
                            renderLastViewedProducts = true;
                            initLastViewedProductsRecommender(lastViewedProductsContainer);
                            lastViewedProductsContainer.classList.remove('d-none');
                        } else if (lastViewedProductsContainer) {
                            lastViewedProductsContainer.remove();
                        }
                    }
                    let firstMobilePaginator = document.querySelector('.mobpag .animation');
                    if (firstMobilePaginator) firstMobilePaginator.classList.add('animation__fadeInUpBig');
                }

                isLastPage = data.end;
                updateImageDisplay(imageDisplay);
                if (isMobile() || isTablet()) {
                    updateUrl();
                    updatePagesButtons();
                    let listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';
                    dataLayer.push({
                        "event": listType,
                        "method": 'pagination',
                        'content_type': page
                    });
                    onVisible(document.querySelectorAll(".fire-product-datalayer"), (element) => itemDatalayerPush('view_item_list', element));
                }
                if (isLastPage) {
                    document.querySelector('footer').classList.remove('d-none');
                    document.querySelector('.product-services-banner').classList.remove('d-none');
                }
                resolve();
                ajaxAddProducts = null;
            },
            function () {
                removePaginatorLoader();
                ajaxAddProducts = null;
            }
        );
    });
}

function updateUrl()
{
    document.querySelectorAll('#product-list-page .products-block').forEach(function (element) {
        if (checkElementIsVisibleInViewport(element)) {
            let page = element.dataset.page;
            let url = element.dataset.url;
            let currentUrl = window.location.href;

            if (url !== currentUrl) {
                //TODO investigar history.pushState(obj, obj.title, obj.url);
                window.history.replaceState(null, null, url);
                /*let listType = document.querySelector('.product-list.search-results') ? 'search_results' : 'product_list';
                dataLayer.push({
                    "event": listType,
                    "method": 'pagination',
                    'content_type': page
                });*/
            }
            document.querySelector('#current-page').value = page;
        }
    });
}

function updatePagesButtons()
{
    document.querySelectorAll(`#product-list-page .products-block`).forEach(function(element) {
        let page = element.dataset.page;
        let nextPageButton = document.querySelector(`#product-list-page .next-page-button[data-page="${page}"]`);
        if (nextPageButton) nextPageButton.classList.remove('opacity-0');
    });
}

function addPaginatorLoader(method)
{
    let container = document.querySelector('#list-products');

    if (container.querySelector('.paginator-loader')) return;

    if (method === 'append') container.insertAdjacentHTML('beforeend', getLoaderHtml());
    else container.insertAdjacentHTML('afterbegin', getLoaderHtml());
}

function removePaginatorLoader()
{
    let container = document.querySelector('#list-products');
    let loader = container.querySelector('.paginator-loader');

    if (loader) loader.remove();
}

function getLoaderHtml()
{
    return `
        <div class="paginator-loader mt-4 pb-4 text-center">
            <div class="spinner-border"></div>
        </div>
    `;
}

function saveCurrentSearch()
{
    let parameters = new URLSearchParams(window.location.search);
    let search = parameters.get('q');

    if (!search) return;

    saveLastSearch(search);
}