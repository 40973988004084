import {documentLoadEvent} from "../../webpack/modules/events";
import {post} from "../../webpack/modules/ajax";
import {areCookiesAccepted, getCookie, setCookie} from "../../webpack/modules/cookies";
import {openModalFromHtml} from "../../webpack/modules-optimized/modal";
import {showPromoChollazos} from "../../scripts/Promotion/popup-chollazos";

const PROMO_POPUP_COOKIE = '_tv_promo_popup';

documentLoadEvent(function() {
    post(
        '/ajax/website/promo/popup',
        {},
        function(response) {
            if (response.promotion) {
                let seenPromotions = [];
                if (getCookie(PROMO_POPUP_COOKIE)) {
                    seenPromotions = getCookie(PROMO_POPUP_COOKIE).split(',');
                }

                if (!seenPromotions.includes(response.promotion.toString())) {
                    openModalFromHtml(response.html);
                    if (areCookiesAccepted()) {
                        seenPromotions = seenPromotions.join(',') + ',' + response.promotion;
                        setCookie(PROMO_POPUP_COOKIE, seenPromotions.replace(/^,+/,''), 1);
                    }
                }
            }
        }
    );
    //showPromoChollazos();
});