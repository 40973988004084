import {clickEvent, documentLoadEvent} from "../../webpack/modules/events";
import {removeOverlay, renderOverlay} from "../../webpack/modules/overlay";
import {ga4StickerFinderTrackEvent} from "../../webpack/modules/analytics";
import {getNextStep, initProductAdvantagesCarousels} from "../../webpack/modules-optimized/product-finder";

let productFinder = document.getElementById('product-finder');

documentLoadEvent(function() {
    showLabel();
});

clickEvent('.product-finder-open', true, function() {
    removeOverlay(document.body);
    renderOverlay(document.body, 'append', ['pf-overlay']);
    productFinder.classList.add('pf-open');
});

clickEvent('#pf-close, .pf-overlay', true, function() {
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentPage(), 'Close');
    productFinder.classList.remove('pf-open');
    removeOverlay(document.body);
    restartProductFinder();
});

clickEvent('#pf-back', false, function() {
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentPage(), 'Back');
    goBackProductFinder();
});

clickEvent('#pf-restart', false, function() {
    let element = document.querySelector('#pf-restart');
    if (window.innerWidth <= 768 && !element.classList.contains('show-tip')) {
        element.classList.add('show-tip');
    } else {
        element.classList.remove('show-tip');
        ga4StickerFinderTrackEvent('Sticker Finder', getCurrentPage(), 'Restart');
        restartProductFinder();
    }
});

clickEvent('#pfs-btn', true, function() {
    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentPage(), 'Start Now');
    answer();
});

clickEvent('.pfa-answer', true, function(element) {
    let id = element.dataset.id;
    let name = element.dataset.name;
    let answersElement = document.querySelector('#pf-answers');
    let answersNamesElement = document.querySelector('#pf-answers-names');
    let answers = answersElement.value;
    let answersNames = answersNamesElement.value;

    answers += ',' + id;
    answers = answers.replace(/^,|,$/g, "");
    answersNames += ',' + name;
    answersNames = answersNames.replace(/^,|,$/g, "");
    answersElement.value = answers;
    answersNamesElement.value = answersNames;

    ga4StickerFinderTrackEvent('Sticker Finder', getCurrentPage(), answersNames);
    answer();
});

clickEvent('#pfpa-next', true, function() {
    let currentProduct = document.querySelector('.pfp-product.current');
    let nextProductPosition = parseInt(currentProduct.dataset.position) + 1;
    let nextProduct = document.querySelector(`.pfp-product.next[data-position="${nextProductPosition}"]`);

    currentProduct.classList.remove('current');
    currentProduct.classList.add('prev');
    nextProduct.classList.remove('next');
    nextProduct.classList.add('current');

    document.querySelector('#pfpa-prev').classList.remove('hidden');
    if (!document.querySelector(`.pfp-product.next`)) document.querySelector('#pfpa-next').classList.add('hidden');
});

clickEvent('#pfpa-prev', true, function() {
    let currentProduct = document.querySelector('.pfp-product.current');
    let prevProductPosition = parseInt(currentProduct.dataset.position) - 1;
    let prevProduct = document.querySelector(`.pfp-product.prev[data-position="${prevProductPosition}"]`);

    currentProduct.classList.remove('current');
    currentProduct.classList.add('next');
    prevProduct.classList.remove('prev');
    prevProduct.classList.add('current');

    document.querySelector('#pfpa-next').classList.remove('hidden');
    if (!document.querySelector(`.pfp-product.prev`)) document.querySelector('#pfpa-prev').classList.add('hidden');
});

clickEvent('.pfp-image', true, function(element, event) {
    event.stopPropagation();

    let linkElement = element.closest('.pfp-product').querySelector('.pfpp-btn a')
    let link = linkElement.href;
    let productReference = linkElement.dataset.reference;
    let productName = linkElement.dataset.name;

    ga4StickerFinderTrackEvent('Sticker Finder', 'Click Result', {name: productName, reference: productReference});
    window.location.href = link;
});

clickEvent('.pfp-product a', true, function(element) {
    let productReference = element.dataset.reference;
    let productName = element.dataset.name;

    ga4StickerFinderTrackEvent('Sticker Finder', 'Click Result', {name: productName, reference: productReference});
});

function showLabel()
{
    let label = document.getElementById('product-finder-label');

    if (!label || !label.classList.contains('pf-label-hidden')) return;

    setTimeout(function () {
        label.classList.remove('pf-label-hidden');
    }, 900);
}

function getCurrentPage()
{
    let page = 'Start Page';
    let currentQuestion = document.querySelector('.pf-answers.current');
    let currentResults = document.querySelector('.pf-results.current');

    if (currentQuestion) page = 'Question '+currentQuestion.dataset.number;
    else if (currentResults) page = 'Result Page';

    return page;
}


async function answer()
{
    let version = document.querySelector('#pf-v').value;
    let answers = document.querySelector('#pf-answers').value;
    let isLastAnswer = answers.split(',').length > 2;
    let body = document.querySelector('#pf-body');

    if (isLastAnswer) showWorkingLoader();
    else if (answers !== '') showLoader();

    let data = await getNextStep(answers, version);
    if (!data) return;

    body.innerHTML += data.view;
    setTimeout(showAnswers, 100);
    setTimeout(updateElementsVisibility, 150);

    if (data.showResults) initProductAdvantagesCarousels(document.querySelectorAll('.pfpp-advantages'));

    if (isLastAnswer) hideWorkingLoader();
    else setTimeout(hideLoader, 150);
}

function showAnswers()
{
    let currentAnswers = document.querySelector('.pf-answers.current');
    let nextAnswers = document.querySelector('.pf-answers.next');
    let results = document.querySelector('.pf-results.next');

    if (currentAnswers) {
        currentAnswers.classList.add('prev');
        currentAnswers.classList.remove('current');
    }

    if (nextAnswers) {
        nextAnswers.classList.remove('next');
        nextAnswers.classList.add('current');
    }

    if (results) {
        results.classList.remove('next');
        results.classList.add('current');
    }
}

function updateElementsVisibility()
{
    let backButton = document.querySelector('#pf-back');
    let restartButton = document.querySelector('#pf-restart');
    let currentQuestion = document.querySelector('.pf-answers.current');
    let currentResults = document.querySelector('.pf-results.current');
    let body = document.querySelector('#pf-body');
    let startContent = document.querySelector('#pf-start-content');
    let title = document.querySelector('#pf-title');
    let footer = document.querySelector('#pf-footer');

    backButton.classList.add('hidden');
    restartButton.classList.add('hidden');
    body.classList.add('bg-white');
    startContent.classList.add('hidden');
    title.classList.add('hidden');
    footer.classList.add('hidden');

    if (!currentQuestion && !currentResults) {
        body.classList.remove('bg-white');
        startContent.classList.remove('hidden');
    }

    if (currentQuestion) {
        title.classList.remove('hidden');
        footer.classList.remove('hidden');
        if (currentQuestion.dataset.number != 1) backButton.classList.remove('hidden');
    }

    if (currentResults) {
        title.classList.remove('hidden');
        footer.classList.remove('hidden');
        backButton.classList.remove('hidden');
        restartButton.classList.remove('hidden');
    }
}

function showLoader()
{
    let loader = document.querySelector('#pf-loading');
    loader.classList.remove('hidden');
    loader.classList.add('d-flex');
}

function hideLoader()
{
    let loader = document.querySelector('#pf-loading');
    loader.classList.add('hidden');
    loader.classList.remove('d-flex');
}

function showWorkingLoader()
{
    let working = document.querySelector('#pf-working');
    working.classList.remove('hidden');
    working.classList.add('d-flex');
}

function hideWorkingLoader()
{
    let working = document.querySelector('#pf-working');
    working.classList.add('hidden');
    working.classList.remove('d-flex');
}

function restartProductFinder()
{
    let results = document.querySelector('.pf-results');

    document.querySelector('#pf-answers').value = '';
    document.querySelector('#pf-answers-names').value = '';
    document.querySelectorAll('.pf-answers').forEach((element) => element.parentNode.removeChild(element));

    if (results) {
        results.classList.add('next');
        results.classList.remove('current');
        setTimeout(function() {
            results.parentNode.removeChild(results);
        }, 200);
    }

    updateElementsVisibility();
}

function goBackProductFinder()
{
    let currentQuestion = document.querySelector('.pf-answers.current');
    let currentResults = document.querySelector('.pf-results.current');
    let lastQuestionNumber = 0;
    if (currentQuestion) {
        lastQuestionNumber = parseInt(currentQuestion.dataset.number) - 1;
    } else if(currentResults) {
        lastQuestionNumber = parseInt(currentResults.dataset.last);
    }
    let previousQuestion = document.querySelector(`.pf-answers.prev[data-number="${lastQuestionNumber}"]`);
    let answers = document.querySelector('#pf-answers').value.split(',');
    let answersNames = document.querySelector('#pf-answers-names').value.split(',');
    answers.pop();
    answersNames.pop();
    document.querySelector('#pf-answers').value = answers.join(',');
    document.querySelector('#pf-answers-names').value = answersNames.join(',');

    if (currentQuestion) {
        currentQuestion.classList.add('next');
        currentQuestion.classList.remove('current');
        setTimeout(function() {
            currentQuestion.parentNode.removeChild(currentQuestion);
        }, 200);
    } else if (currentResults) {
        currentResults.classList.add('next');
        currentResults.classList.remove('current');
        setTimeout(function() {
            currentResults.parentNode.removeChild(currentResults);
        }, 200);
    }
    if (previousQuestion) {
        previousQuestion.classList.remove('prev');
        previousQuestion.classList.add('current');
    }
    updateElementsVisibility();
}