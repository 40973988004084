import {documentLoadEvent} from "../../../webpack/modules/events";
import Splide from "@splidejs/splide";

documentLoadEvent(function () {
    let element = document.querySelector('#splide-header-outer-menu');
    if (element) {
        let splide = new Splide(element, {
            pagination: false,
            arrows: false,
            autoWidth: true
        });

        splide.mount();
    }
});