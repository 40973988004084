import {post} from "../modules/ajax";

let ajaxTags = null;
export function getTags(search)
{
    return new Promise(resolve => {
        if (ajaxTags) ajaxTags.abort();
        if (search.trim().length < 2) return resolve([]);

        ajaxTags = post(
            '/ajax/website/search/get-suggestions',
            {search: search},
            function(data) {
                ajaxTags = null;
                resolve(data.tags);
            },
            function () {
                ajaxTags = null;
                resolve([])
            }
        );
    });
}