import {clickEvent, keyupEvent, submitEvent} from "../../../webpack/modules/events";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";
import {subscribe} from "../../../webpack/modules-optimized/newsletter";

let writeMailFooter = true;
keyupEvent('footer #footer-newsletter-form input[type="email"]', false, function() {
    setTimeout(() => {
        if (writeMailFooter) {
            ga4TrackSimpleEvent({
                event: 'footer_newsletter',
                method: 'type_email',
            });
            writeMailFooter = false;
        }
    }, 2000);
});

clickEvent('footer #footer-newsletter-form button[type="submit"]', false, function () {
    let form = document.querySelector('footer #footer-newsletter-form');
    let email = form.querySelector('input[type="email"]');
    let terms = form.querySelector('input[name="privacy-terms"]');
    let newsletter = form.querySelector('input[name="mailing"]');

    if (email.value === '' || !terms.checked || !newsletter.checked) {
        ga4TrackSimpleEvent({
            event: 'footer_newsletter',
            method: 'error',
        });
    }
});

submitEvent('footer #footer-newsletter-form', false, async function(form, event) {
    event.preventDefault();
    let email = form.querySelector('input[type="email"]');
    let voucherForm = document.querySelector('footer #form-voucher-footer');
    let view = await subscribe(email.value);

    if (view === '') return;

    view = view.replaceAll('text-dark', 'text-white');
    view = view.replaceAll('text-black', 'text-white');
    voucherForm.innerHTML = view;
    form.classList.add('d-none');
    voucherForm.classList.remove('d-none');

    dataLayer.push({
        event: 'newsletter_subscribe',
        method: 'popup'
    });
    ga4TrackSimpleEvent({
        event: 'footer_newsletter',
        method: 'suscribe',
    });
});
