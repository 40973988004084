import {customEvent, documentLoadEvent, submitEvent} from "../../webpack/modules/events";
import {showNewsletterPopup, subscribe} from "../../webpack/modules-optimized/newsletter";
import {setCookie} from "../../../library/cookies";

documentLoadEvent(function() {
    showNewsletterPopup();
});

submitEvent('#popup-newsletter #popup-newsletter-form', true, async function(form, event) {
    event.preventDefault();
    let email = form.querySelector('input[type="email"]');
    let voucherForm = document.querySelector('#popup-newsletter #form-voucher-modal');
    let view = await subscribe(email.value);

    if (view === '') return;

    voucherForm.innerHTML = view;
    form.closest('#form-data-modal').classList.add('d-none');
    voucherForm.classList.remove('d-none');
    dataLayer.push({
        event: 'newsletter_subscribe',
        method: 'popup'
    });
    newsletterPopupDismissed();
});

customEvent('hide.bs.modal', '#popup-newsletter', true, function() {
    newsletterPopupDismissed();
});

function newsletterPopupDismissed()
{
    if (typeof (Storage) !== 'undefined') {
        let d = new Date();
        localStorage.setItem('tvn_shnewspop', d.getTime());
    } else {
        setCookie('tvn_shnewspop', '1', 30);
    }
}