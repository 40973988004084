import Splide from "@splidejs/splide";

export function initImageSliders(container)
{
    let allReviewImages = container.querySelectorAll('.canvas-review-images');
    allReviewImages.forEach(function(slider) {
        new Splide(slider, {
            type: 'slide',
            autoWidth: true,
            pagination: false,
            lazyLoad: 'sequential',
            focus: 0,
            omitEnd: true,
        }).mount();
    });
}