import {clickEvent, windowScrollEvent} from "../../../webpack/modules/events";
import {isDesktop} from "../../../webpack/modules/devices";

clickEvent('#scroll-top-button', false, function() {
    window.scrollTo({top: 0, behavior: 'smooth'});
});

let scrollTimer = null;
windowScrollEvent(function() {
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(function() {
        let scrollButton = document.querySelector('#scroll-top-button');

        if (!scrollButton || !isDesktop()) return;
        if (window.scrollY > window.innerHeight) scrollButton.classList.remove('d-none');
        else scrollButton.classList.add('d-none');
    }, 200);
});