import {clickEvent, customEvent, documentLoadEvent} from "../../webpack/modules/events";
import {slideDown, slideUp} from "../../webpack/modules/element-visibility";
import {ga4TrackSimpleEvent} from "../../webpack/modules/analytics";
import {onVisible} from "../../scripts/Tools/visible-element-observer";

let leftMenu = document.querySelector('#left-menu');

documentLoadEvent(() => replaceCountryLinks());

clickEvent('#left-menu #country-selector-btn', false, function() {
    let button = leftMenu.querySelector('#country-selector-btn');
    let status = button.dataset.status;
    if (status === 'in-progress') return;

    if (status === 'closed') openCountrySelector();
    else closeCountrySelector();
});

onVisible(leftMenu.querySelectorAll("#country-selector img"), (image) => image.src = image.dataset.src);

customEvent('hidden.bs.offcanvas', '#left-menu', false, function() {
    let mainSectionId = leftMenu.querySelector('.hm-section[data-type="main"]').dataset.id;
    navigateHeaderMenu(mainSectionId, false);
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'close'
        }
    );
});

clickEvent('#left-menu .hm-navigate', false, function(button) {
    navigateHeaderMenu(button.dataset.id, true);
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'navigate',
            link_id: button.dataset.linkId ? button.dataset.linkId : null,
            link_text: button.dataset.linkText ? button.dataset.linkText : null
        }
    );
});

clickEvent('#left-menu .hm-back', false, function(button) {
    navigateHeaderMenu(button.dataset.id, false);
    ga4TrackSimpleEvent(
        {
            event: 'header_menu',
            method: 'back'
        }
    );
});

function replaceCountryLinks()
{
    let alternates = document.querySelectorAll('link[rel=alternate]');
    alternates.forEach(function(alternate) {
        let hreflang = alternate.getAttribute('hreflang');
        let locale = hreflang.replace("-","_");
        let href = alternate.getAttribute('href');
        let link = leftMenu.querySelector(`.selector-website[data-locale=${locale}]`);
        if (link) link.setAttribute("href", href);
    });
}

function openCountrySelector()
{
    let button = leftMenu.querySelector('#country-selector-btn');
    let status = button.dataset.status;
    if (status !== 'closed') return;
    let icon = button.querySelector('.direction-icon');
    let countrySelector = leftMenu.querySelector('#country-selector');

    button.dataset.status = 'in-progress';
    icon.classList.add('fa-rotate-180');
    slideDown(countrySelector, 200, () => button.dataset.status = 'open');
    ga4TrackSimpleEvent(
        {
            event: 'header_country_selector',
            method: 'open'
        }
    );
}

function closeCountrySelector()
{
    let button = leftMenu.querySelector('#country-selector-btn');
    let status = button.dataset.status;
    if (status !== 'open') return;
    let icon = button.querySelector('.direction-icon');
    let countrySelector = leftMenu.querySelector('#country-selector');

    button.dataset.status = 'in-progress';
    icon.classList.remove('fa-rotate-180');
    slideUp(countrySelector, 200, () => button.dataset.status = 'closed');
    ga4TrackSimpleEvent(
        {
            event: 'header_country_selector',
            method: 'close'
        }
    );
}

function navigateHeaderMenu(id, updateBackButton)
{
    let current = leftMenu.querySelector('.hm-section.active');
    let next = leftMenu.querySelector(`.hm-section[data-id="${id}"]`);
    current.classList.remove('active');
    next.classList.add('active');
    if (updateBackButton) {
        let backButton = next.querySelector('.hm-back');
        if (backButton) backButton.dataset.id = current.dataset.id;
    }
}