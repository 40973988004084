import {clickEvent} from "../../../webpack/modules/events";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";

clickEvent('.breadcrumb.breadcrumb-expandable .breadcrumb-expand', false, function(button) {
    button.classList.add('d-none');
    let items = document.querySelectorAll('.breadcrumb.breadcrumb-expandable .breadcrumb-item:not(.hidden)');
    items.forEach((item) => item.classList.remove('d-none'));
    ga4TrackSimpleEvent({
        event: 'breadcrumb',
        method: 'see_more'
    });
});
