import {getCookie} from "./cookies";
import {post} from "../modules/ajax";
import {openModalFromHtml} from "./modal";
import {sendPopupEvent} from "../../../library/analytics";

export function showPopup()
{
    let cookieChoice = getCookie('_tv_cookie_choice');
    let popUpIsShowing = document.getElementById('modal-pop-up-smarter-click');
    let cookie = getCookie('_tv_cookie_pop_up_smarter_click');

    if ((cookieChoice !== 'ALL' && cookieChoice !== 'BASIC') || popUpIsShowing) return;

    if (cookie === '' || !cookie) {
        post(
            '/ajax/pop-ups/smarter-click/render',
            {'pageV2': true},
            function (data) {
                if (!data.popUp) return;
                openModalFromHtml(data.popUp);
                sendPopupEvent('popup_discount', data.popupType, 'show');
            },
        );
    }
}

let ajaxEmail = null;
export function sendEmail(email)
{
    if (ajaxEmail) ajaxEmail.abort();

    return new Promise(resolve => {
        ajaxEmail = post(
            '/ajax/pop-ups/smarter-click/send-email',
            { email: email.value },
            function() {
                resolve();
            },
            function () {
                resolve();
            }
        );
    });
}