import {customEvent} from "../../webpack/modules/events";
import {hideElement, showElement} from "../../webpack/modules/element-visibility";

customEvent('show.bs.offcanvas', '.offcanvas', false, function() {
    let chatWidget = document.querySelector('.chatwithapp');
    if (chatWidget) hideElement(chatWidget);
    let tawkWidget = document.querySelector('.widget-visible iframe');
    if (tawkWidget) hideElement(tawkWidget)
});

customEvent('hidden.bs.offcanvas', '.offcanvas', false, function() {
    let chatWidget = document.querySelector('.chatwithapp');
    if (chatWidget) showElement(chatWidget);
    let tawkWidget = document.querySelector('.widget-visible iframe');
    if (tawkWidget) showElement(tawkWidget);
});