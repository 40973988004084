import {clickEvent, customEvent} from "../../../webpack/modules/events";
import {showShareCartLink} from "../../../webpack/modules-optimized/cart";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";

clickEvent('#cart .share-cart', true, function() {
    showShareCartLink();
});

customEvent('hide.bs.offcanvas', '#cart', false, function() {
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'close_cart'
        }
    );
});

customEvent('show.bs.offcanvas', '#cart', false, function() {
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'show_cart'
        }
    );
});

clickEvent('#cart #paypal-button', true, function() {
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'paypal'
        }
    );
});

clickEvent('#cart #amazon-pay-button', true, function() {
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'amazon_pay'
        }
    );
});