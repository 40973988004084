import {
    clickEvent,
    documentLoadEvent,
    fireKeyupEvent, fireSubmitEvent,
    focusEvent,
    keyupEvent,
    resetEvent, submitEvent
} from "../../../webpack/modules/events";
import {isDesktop} from "../../../webpack/modules/devices";
import {getLastSearches, removeSearch} from "../../../webpack/modules/last-searches";
import {hideElement, showElement} from "../../../webpack/modules/element-visibility";
import {getTags} from "../../../webpack/modules-optimized/searcher";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";
import {makeUrl} from "../../../webpack/modules/url";

let lastSearches = [];
let tags = [];
let maxSuggestions = 12;
let maxLastSearches = 4;
let disabledKeyboardCodes = [9, 13, 16, 17, 18, 19, 20, 27, 37, 38, 39, 40, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123]

documentLoadEvent(function () {
    loadLastSearches();
});

focusEvent('.search-form input', false, function() {
    let form = getForm();
    let suggestions = form.querySelector('.suggestions');

    if (lastSearches.length || tags.length) showElement(suggestions);
});

keyupEvent('.search-form input', false, async function(element, e) {
    if (disabledKeyboardCodes.includes(e.keyCode)) return;
    let form = getForm();
    let search = form.querySelector('input').value.trim();
    let suggestions = form.querySelector('.suggestions');
    let btnClear = form.querySelector('.btn-clear');

    loadLastSearches();
    await loadTags();

    if (search !== '') showElement(btnClear);
    else hideElement(btnClear);
    if (lastSearches.length || tags.length) showElement(suggestions);
    else hideElement(suggestions);
});

clickEvent('.search-form .btn-clear', false, function() {
    let form = getForm();
    let input = form.querySelector('input');

    input.focus();
    input.value = '';
    fireKeyupEvent(input, false);
});

clickEvent('body', false, function(element, e){
    let target = e.target;
    let form = getForm();
    let suggestions = form.querySelector('.suggestions');

    if (!target.closest('.header-searcher') && !target.classList.contains('header-searcher')) hideElement(suggestions);
});

resetEvent('.search-form', false, function(element, event) {
    event.preventDefault();
    ga4TrackSimpleEvent(
        {
            event: 'searcher',
            method: 'delete'
        }
    );
});

clickEvent('.search-form .remove-saved-search', true, function(element, e) {
    e.stopPropagation();
    e.preventDefault();

    let form = getForm();
    let input = form.querySelector('input');
    let suggestion = element.closest('.suggestion');

    ga4TrackSimpleEvent(
        {
            event: 'searcher',
            method: 'delete-saved-searches'
        }
    );
    removeSearch(suggestion.dataset.suggestion);
    fireKeyupEvent(input, false);
});

clickEvent('.search-form .suggestion', true, function(element, e) {
    if (e.target.classList.contains('remove-saved-search')) return;
    let form = getForm();
    let url = form.querySelector('.all-products-url').value;
    let suggestion = element.dataset.suggestion;

    window.location.href = encodeURI(makeUrl('/'+url+'/search', {'q': suggestion}));
});

function loadLastSearches()
{
    let form = getForm();
    let suggestions = form.querySelector('.suggestions');
    let search = form.querySelector('input').value.trim();
    let container = suggestions.querySelector('.last-searches');

    let lastSearchesAdded = 0;
    let html = '';

    lastSearches = [];
    container.innerHTML = '';

    getLastSearches().forEach(function (lastSearch) {
        if (lastSearchesAdded >= maxLastSearches) return;
        html += getSuggestionLineHTML(search.toLowerCase(), lastSearch.toLowerCase(), true);
        lastSearches.push(lastSearch);
        lastSearchesAdded++;
    });

    container.insertAdjacentHTML('beforeend', html);
    if (container.innerHTML !== '') showElement(container);
    else hideElement(container);
}

async function loadTags()
{
    return new Promise(async resolve => {
        let form = getForm();
        let search = form.querySelector('input').value;
        let tagsFromAjax = await getTags(search.toLowerCase());
        let addedSuggestions = lastSearches.length;
        let html = '';
        let container = form.querySelector('.suggestions .tags');
        tags = [];

        tagsFromAjax.forEach(function (tag) {
            if (addedSuggestions > maxSuggestions) return;
            html += getSuggestionLineHTML(search, tag);
            tags.push(tag);
            addedSuggestions++;
        });

        container.innerHTML = html;

        if (container.innerHTML !== '') showElement(container);
        else hideElement(container);

        resolve();
    });
}

function getForm()
{
    return isDesktop() ? document.querySelector('header .desktop .search-form') : document.querySelector('header .mobile .search-form');
}

function getSuggestionLineHTML(search, suggestion, isLastSearch = false)
{
    let normalText = '';
    let boldText = suggestion;

    if (!isLastSearch) {
        normalText = search;
        boldText = suggestion.slice(search.length);
    }

    return `
        <div class="col-12 py-1 suggestion ${isLastSearch ? 'saved-search' : ''}" data-suggestion="${suggestion}">
            <button class="btn py-0 shadow-none remove-saved-search tvi tvi-times text-dark px-2 align-middle fw-bold ${isLastSearch ? '' : 'invisible'}"></button>
            <span class="suggestion-name text-gray"><span>${normalText}</span>${boldText}</span>
        </div>
    `;
}