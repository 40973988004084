import {post} from "../modules/ajax";
import {renderAmazonPayButton} from "../modules/amazon-pay";
import {renderPayPalButton} from "../modules/paypal";
import Splide from "@splidejs/splide";
import {adjustArrows} from "./recommenders";
import {openModalFromHtml} from "./modal";
import {getCookie} from "../../../library/cookies";
import {getLastViewedProducts} from "../modules/last-viewed-products";
import {showOffcanvas} from "./offcanvas";
import {calculatePrice, getSizesPrices} from "./product";
import CssFilterConverter from "css-filter-converter";

export async function openCart()
{
    let cart = document.querySelector('#cart');
    if (!cart) return;
    showOffcanvas('cart');
    showLoader();
    await renderCart();
    hideLoader();
}

export function showLoader()
{
    let cart = document.querySelector('#cart');
    if (cart.querySelector('.cart-loader')) return;
    let loader = getLoaderHtml();
    let body = cart.querySelector('.offcanvas-body');
    if (body) body.insertAdjacentHTML('afterend', loader);
    else cart.innerHTML += loader;
}

export function hideLoader()
{
    let cart = document.querySelector('#cart');
    let loader = cart.querySelector('.cart-loader');
    if (loader) loader.remove();
}

let ajaxRender = null;
export function renderCart()
{
    if (ajaxRender) ajaxRender.abort();
    return new Promise(resolve => {
        let cart = document.querySelector('#cart');
        let cartType = cart.dataset.type;
        ajaxRender = post(
            '/ajax/website/cart/render',
            {
                renderNew: true,
                cartType: cartType,
            },
            function (response) {
                let data = response.data;
                cart.innerHTML = data.view;
                if (cart.querySelector('#amazon-pay-button')) renderAmazonPayButton(data.amazonPayCredentials);
                if (cart.querySelector('#paypal-button')) renderPayPalButton(data.currency, data.total);
                initComplementsSlider();
                initImagesPreview();
                ajaxRender = null;
                resolve();
            },
            function(response) {
                let data = response.data;
                if (!data) return;
                cart.innerHTML = data.view;
                initEmptyCartRecommender();
                renderLastViewedProductRecommender();
                ajaxRender = null;
                resolve();
            }
        );
    });
}

export function showShareCartLink()
{
    post(
        '/website/cart/get-share-link',
        {},
        function(data) {
            openModalFromHtml(data.view);
        }
    )
}

let ajaxItemQuantity = null;
export function updateItemQuantity(itemId, quantity)
{
    if (ajaxItemQuantity) ajaxItemQuantity.abort();
    return new Promise(resolve => {
        ajaxItemQuantity = post(
            '/ajax/website/cart/update-item-quantity',
            {
                id: itemId,
                quantity: quantity
            },
            function (data) {
                ajaxItemQuantity = null;
                resolve(data.oldQuantity);
            },
            function () {
                ajaxItemQuantity = null;
                resolve(null);
            }
        )
    });
}

export async function updateItemsNumber(update = false)
{
    let items = await getItemsNumber(update);
    let elements = document.querySelectorAll('header .open-cart .items');
    elements.forEach(function(item) {
        if (items === 0) item.innerHTML = '';
        else item.innerHTML = items;
    });
}

export function removeItem(itemId) {
    return new Promise(resolve => {
        post(
            '/ajax/website/cart/remove-item',
            {id: itemId},
            function () {
                resolve();
            },
            function () {
                resolve();
            }
        );
    });
}

export function loadModifyItemModal(itemId)
{
    return new Promise(resolve => {
        post(
            '/ajax/website/cart/load-modify-item',
            {id: itemId},
            function (data) {
                openModalFromHtml(data.view);
                resolve();
            }
        )
    });
}

let ajaxAddItem = null;
export function addItem(data)
{
    if (ajaxAddItem) ajaxAddItem.abort();
    return new Promise(resolve => {
        ajaxAddItem = post(
            '/ajax/website/cart/add-item',
            data,
            function(response) {
                ajaxAddItem = null;
                resolve({'error': false, 'items': response.items});
            },
            function(response) {
                ajaxAddItem = null;
                resolve({'error': true, 'message': response.message, 'type': response.type, 'productIndex': response.productIndex, 'productMessage': response.productMessage});
            }
        );
    });
}

let ajaxAddItems = null;
export function addItems(data)
{
    if (ajaxAddItems) ajaxAddItems.abort();
    return new Promise(resolve => {
        ajaxAddItems = post(
            '/ajax/website/cart/add-items',
            data,
            function (response) {
                ajaxAddItems = null;
                resolve({'error': false, 'items': response.items});
            },
            function (response) {
                ajaxAddItems = null;
                resolve({'error': true, 'message': response.message, 'type': response.type});
            }
        );
    });
}

function getLoaderHtml()
{
    return `
        <div class="cart-loader d-flex bg-white bg-opacity-75 position-absolute top-0 w-100 h-100 align-items-center justify-content-center z-index-inherit">
            <div class="spinner-border"></div>
        </div>
    `;
}

function initComplementsSlider()
{
    let complements = document.querySelector('#cart #cart-complements');

    if (!complements) return;

    new Splide(complements, {
        perPage: 2,
        perMove: 1,
        lazyLoad: 'sequential',
        arrows: false,
        pagination: false,
        padding: { right: '20%'},
        gap: 8,
    }).mount();
}

function initEmptyCartRecommender()
{
    let recommenders = document.querySelectorAll('#cart .recommender');
    recommenders.forEach(function(recommender) {
        let splide = new Splide(recommender, {
            type: 'loop',
            perPage: 2,
            perMove: 1,
            lazyLoad: 'nearby',
            gap: 8,
            pagination: false,
            rewind: true,
            arrows: false,
            padding: {right: '23%'}
        }).mount();

        splide.on('mounted resize', function () {
            adjustArrows(recommender);
        });
    });
}

function renderLastViewedProductRecommender()
{
    post(
        '/ajax/website/product-list/get-last-viewed-products-recommender',
        {lastViewedProducts: getLastViewedProducts()},
        function(data) {
            let recommender = data.recommender;
            let container = document.querySelector('#cart-last-viewed-products-container');
            container.innerHTML = recommender;

            let recommenders = document.querySelectorAll('#cart #cart-last-viewed-products-container .recommender[data-type="last-viewed-products"]');
            recommenders.forEach(function(recommender) {
                let splide = new Splide(recommender, {
                    type: 'loop',
                    perPage: 2,
                    perMove: 1,
                    lazyLoad: 'nearby',
                    gap: 8,
                    pagination: false,
                    rewind: true,
                    arrows: false,
                    padding: {right: '23%'}
                }).mount();
                splide.on('mounted resize', function () {
                    adjustArrows(recommender);
                });
            });
        }
    );
}

function getItemsNumber(update) {
    return new Promise((resolve) => {
        let cookie = getCookie('_tv_shopping_cart');
        if (cookie === '') resolve(0);
        if (!update && localStorage.getItem("cartNumberItems")) {
            resolve(parseInt(localStorage.getItem("cartNumberItems")));
        } else {
            post(
                '/ajax/website/cart/get-items-number',
                {},
                function(data) {
                    localStorage.setItem('cartNumberItems', data.items);
                    resolve(parseInt(data.items));
                },
                function () {
                    resolve(0);
                }
            )
        }
    });
}

let ajaxModifyItem = null;
export function modifyItem(data)
{
    if (ajaxModifyItem) ajaxModifyItem.abort();
    return new Promise((resolve) => {
        ajaxModifyItem = post(
            '/ajax/website/cart/modify-item',
            data,
            function(response) {
                ajaxModifyItem = null;
                resolve({'error': false, 'items': response.items});
            },
            function(response) {
                ajaxModifyItem = null;
                resolve({'error': true, 'type': response.type, 'message': response.message});
            }
        );
    });
}

export async function updateButtonsPrice(container)
{
    let buttons = container.querySelectorAll('.btn-add-to-cart');

    buttons.forEach((button) => showButtonLoader(button));
    await calculatePrice(container);
    buttons.forEach((button) => hideButtonLoader(button));
}

export async function updateCartSizesPrices(container)
{
    let productIndex = container.dataset.productIndex || 1;
    let prices = await getSizesPrices(container);

    prices.forEach(function (element) {
        let id = element.id;
        let price = element.price;
        let oldPrice = element.oldPrice;
        let labels = element.labels;
        let input = container.querySelector('.product-options[data-index="'+productIndex+'"] input[name="size"][value="'+id+'"]');
        if (input) {
            let label = container.querySelector('label[for="'+input.id+'"]');

            input.dataset.price = price;
            label.querySelector('.size-price').innerHTML = price;
            if (oldPrice) label.querySelector('.size-old-price').innerHTML = oldPrice;
            label.querySelector('.size-labels').innerHTML = labels;
        }
    });
}

function showButtonLoader(button)
{
    let loader = button.querySelector('.loader');
    let elementToHide = button.querySelector('.text');

    button.disabled = true;
    if (elementToHide) elementToHide.classList.add('d-none');
    if (loader) loader.classList.remove('d-none');
}

function hideButtonLoader(button)
{
    let loader = button.querySelector('.loader');
    let elementToHide = button.querySelector('.text');

    button.disabled = false;
    if (loader) loader.classList.add('d-none');
    if (elementToHide) elementToHide.classList.remove('d-none');
}

function initImagesPreview()
{
    let itemsImages = document.querySelectorAll('#cart .cart-item img');

    if (!itemsImages) return;

    itemsImages.forEach(function(image) {
        let hexCode = image.dataset.colorHexCode;
        let mirrored = image.dataset.mirrored;

        if (hexCode && hexCode.includes('ffffff')) image.parentElement.style.backgroundColor = '#e3e3e3';
        else image.parentElement.style.backgroundColor = '';
        if (hexCode) image.style.filter = CssFilterConverter.hexToFilter(hexCode)['color'];
        else image.style.filter = '';
        if (mirrored === 'true') image.style.transform = 'scaleX(-1)';
        else image.style.transform = '';
    });
}