import {clickEvent} from "../../../../webpack/modules/events";
import {hideLoader, showLoader} from "../../../../webpack/modules-optimized/offcanvas";
import {makeUrl} from "../../../../webpack/modules/url";
import {get} from "../../../../webpack/modules/ajax";
import {ga4TrackSimpleEvent} from "../../../../webpack/modules/analytics";
import {initImageSliders} from "../../../../webpack/modules-optimized/reviews";

clickEvent('#product-list-page .open-product-list-reviews', false, async function(element) {
    let container = document.querySelector('#reviews-offcanvas');
    let categoryId = element.dataset.categoryId;
    let productTypeId = element.dataset.productTypeId;
    if (categoryId === '' && productTypeId === '') return;

    showLoader(container);
    let response = await loadReviews(categoryId, productTypeId);
    container.querySelector('.reviews-average').innerHTML = response.average;
    container.querySelector('.reviews-list').innerHTML = response.list;
    container.querySelector('#load-more-product-reviews').classList.add('d-none');
    initImageSliders(container);
    ga4TrackSimpleEvent({
        'event': 'product_list',
        'method': 'show_category_reviews',
        'content_type': element.dataset.categoryId
    });
    hideLoader(container);
});

function loadReviews(categoryId, productTypeId)
{
    return new Promise(resolve => {
        let url = '/ajax/website/get-product-list-reviews-canvas/';
        let params = {};
        if (categoryId) params['categoryId'] = categoryId;
        if (productTypeId) params['productTypeId'] = productTypeId;
        url = makeUrl(url, params);
        get(url, {}, (response) => resolve(response));
    });
}