import {post} from "../modules/ajax";
import Splide from "@splidejs/splide";
import {adjustArrows} from "./recommenders";

let variableName = '_tv_last_viewed_products';
let maxProducts = 20;

export function initLastViewedProductsRecommender(container)
{
    post(
        '/ajax/website/product-list/get-last-viewed-products-recommender',
        {lastViewedProducts: getLastViewedProducts()},
        function(data) {
            if (data.recommender === '') {
                container.remove();
                return;
            }
            let recommender = data.recommender;
            container.innerHTML = recommender;
            recommender = container.querySelector('.recommender[data-type="last-viewed-products"]');
            let numberOfSlides = recommender.querySelectorAll('.splide__slide').length;
            let splide = new Splide(recommender, {
                perPage: 7,
                perMove: 7,
                lazyLoad: 'nearby',
                gap: "20px",
                pagination: false,
                drag: false,
                type: numberOfSlides > 7 ? 'loop' : 'slide',
                arrows: numberOfSlides > 7,
                breakpoints: {
                    1200: {
                        perPage: 5,
                        perMove: 5,
                        type: numberOfSlides > 5 ? 'loop' : 'slide',
                        arrows: numberOfSlides > 5
                    },
                    750: {
                        perPage: 2,
                        perMove: 2,
                        type: numberOfSlides > 2 ? 'loop' : 'slide',
                        padding: { right: '23%'},
                        arrows: false,
                        drag: numberOfSlides > 2,
                        pagination: numberOfSlides > 2,
                    }
                }
            }).mount();

            splide.on('mounted resize',function () {
                adjustArrows(recommender);
            });
        }
    );
}

export function addLastViewedProduct(reference)
{
    let lastViewedProducts = JSON.parse(localStorage.getItem(variableName)) || [];
    if (!lastViewedProducts.includes(reference)) lastViewedProducts.push(reference);
    if (lastViewedProducts.length > maxProducts) lastViewedProducts.shift();
    localStorage.setItem(variableName, JSON.stringify(lastViewedProducts));
}

export function getLastViewedProducts()
{
    return JSON.parse(localStorage.getItem(variableName));
}