import {post} from "../modules/ajax";
import {openModalFromHtml} from "./modal";
import {openOffcanvasFromHtml} from "./offcanvas";

export function getCookie(name)
{
    name = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return null;
}

export function setCookie(name, value, exDays)
{
    let d = new Date();

    if (areCookiesAccepted()) d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    else d.setTime(d.getTime() + (exDays * -1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function deleteCookie(name)
{
    let d = window.location.hostname.split(".");
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain="+d[1]+'.'+d[2]+";";
}

export function deleteAllCookies()
{
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        deleteCookie(cookies[c].split(";")[0].split("=")[0]);
    }
}

export function areCookiesAccepted()
{
    return !localStorage.getItem('_tv_cookie_choice');
}

export function showModalCookies()
{
    let cookielessPage = document.querySelector('.cookieless-page')
    if (!cookielessPage) {
        let cookieConsent = getCookie('_tv_cookie_consent');
        let cookieCheckout = getCookie('_tv_in_checkout');

        if (cookieConsent || cookieCheckout) return;

        post(
            '/ajax/website/cookies/get-modal-cookies',
            {},
            function (data) {
                openModalFromHtml(data.html);
            },
        );
    }
}

export function showOffcanvasCookies()
{
    let cookielessPage = document.querySelector('.cookieless-page')
    let cookieConsent = getCookie('_tv_cookie_consent');
    let cookieCheckout = getCookie('_tv_in_checkout');

    if (cookieConsent || cookieCheckout) return;

    if (!cookielessPage) {
        post(
            '/ajax/website/cookies/get-offcanvas-cookies',
            {},
            function (data) {
                openOffcanvasFromHtml(data.html, {}, true);
            }
        );
    }
}

export function showModalConfigCookies()
{
    post(
        '/ajax/website/cookies/get-new-modal-config-cookies',
        {},
        function(data) {
            openModalFromHtml(data.html);
            dataLayer.push({
                event: 'cookies',
                method: 'show_configuration'
            });
        }
    );
}

export function acceptAllCookies(days, eventName = 'cookies')
{
    if (localStorage.getItem('ga:clientId')) localStorage.removeItem('ga:clientId');
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    setCookie('_tv_cookie_choice', 'ALL', days);
    if (window.gtag) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted'
        });
    }
    if (window.fbq) fbq('consent', 'grant');
    dataLayer.push({
        event: eventName || 'cookies',
        method: 'accept_all'
    });
    sendExperimentEvent();
}

export function acceptAnalyticsCookies(days)
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    let cookieChoice = 'ANALYTICS';
    if (getCookie('_tv_cookie_choice')) cookieChoice = getCookie('_tv_cookie_choice')+'+'+cookieChoice;
    setCookie('_tv_cookie_choice', cookieChoice, days);
    if (window.gtag) {
        gtag('consent', 'update', {
            'analytics_storage': 'granted',
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied'
        });
    }
    if (window.fbq) {
        fbq('consent', 'revoke');
    }
    dataLayer.push({
        event: 'cookies',
        method: 'accept_analytics_and_basic'
    });
    sendExperimentEvent();
}

export function acceptMarketingCookies(days)
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    let cookieChoice = 'MARKETING';
    if (getCookie('_tv_cookie_choice')) cookieChoice = getCookie('_tv_cookie_choice')+'+'+cookieChoice;
    setCookie('_tv_cookie_choice', cookieChoice, days);

    if (window.gtag) {
        gtag('consent', 'update', {
            'analytics_storage': 'granted',
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted'
        });
    }
    if (window.fbq) {
        fbq('consent', 'grant');
    }
    dataLayer.push({
        event: 'cookies',
        method: 'accept_marketing_and_basic'
    });
}

export function acceptBasicCookies(days)
{
    if (!areCookiesAccepted()) localStorage.removeItem('_tv_cookie_choice');
    setCookie('_tv_cookie_consent', 'y', days);
    let cookieChoice = 'BASIC';
    if (getCookie('_tv_cookie_choice')) cookieChoice = getCookie('_tv_cookie_choice')+'+'+cookieChoice;
    setCookie('_tv_cookie_choice', cookieChoice, days);
}

function sendExperimentEvent()
{
    if (window.sendExperimentData) window.sendExperimentData();
}