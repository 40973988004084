import {slideUp, slideDown} from "../../webpack/modules-optimized/element-visibility";
import {clickEvent, submitEvent} from "../../webpack/modules/events";
import {sendForm} from "../../webpack/modules/form";
import {openModalFromHtml} from "../../webpack/modules-optimized/modal";

clickEvent('#message-sos .open-form', false, openForm);

clickEvent('#message-sos .close-form', false, closeForm);

submitEvent('#message-sos form', false, function(form, event) {
    event.preventDefault();
    sendForm(
        form,
        function (data) {
            openModalFromHtml(data.modal);
            closeForm();
        },
        function(data) {
            openModalFromHtml(data.modal);
            closeForm();
        });
});

async function openForm()
{
    let container = document.querySelector('#message-sos');
    let openForm = container.querySelector('.open-form');
    let form = container.querySelector('.form');

    await slideUp(container);
    openForm.classList.remove('d-flex');
    openForm.classList.add('d-none');
    form.classList.remove('d-none');
    slideDown(container);
}

async function closeForm()
{
    let container = document.querySelector('#message-sos');
    let openForm = container.querySelector('.open-form');
    let form = container.querySelector('.form');

    await slideUp(container);
    form.classList.add('d-none');
    openForm.classList.remove('d-none');
    openForm.classList.add('d-flex');
    slideDown(container);
}