import {get} from "../modules/ajax";
import Splide from "@splidejs/splide";

let ajaxNextStep = null;
export function getNextStep(answers, version)
{
    if (ajaxNextStep) ajaxNextStep.abort();

    return new Promise(resolve => {
        ajaxNextStep = get(
            '/ajax/website/product-finder/answer',
            {
                answers: answers,
                version: version,
            },
            function(data) {
                ajaxNextStep = null;
                resolve(data);
            },
            function() {
                ajaxNextStep = null;
                resolve(null);
            }
        );
    });
}

export function initProductAdvantagesCarousels(sliders)
{
    sliders.forEach(function(element) {
        new Splide(element, {
            type: 'fade',
            rewind: true,
            arrows: false,
            pagination: false,
            drag: false,
            autoplay: true,
            speed: 1000
        }).mount();
    });
}