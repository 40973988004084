import {post} from "../modules/ajax";

export function getPhoneInfo()
{
    return new Promise(resolve => {
        post(
            '/ajax/website/get-phone-info',
            {},
            function (response) {
                resolve(response.view);
            },
            function() {
                resolve('');
            }
        );
    });
}