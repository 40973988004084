import {changeEvent, customEvent} from "../../../../../webpack/modules/events";

changeEvent('#product-popup input[name="material-canvas"]', true, function(element) {
    let container = element.closest('#product-popup');
    let sizes = container.querySelectorAll('.product-option[data-type="size"] input[name="size"]');
    sizes.forEach(function (size) {
        size.checked = false;
        if (size.dataset.material == element.value) {
            size.parentElement.style.display ='block';
        } else {
            size.parentElement.style.display= 'none';
        }
    });
});

customEvent('show.bs.modal', '#modal-product', true, function(element, e) {
    let material = element.querySelector('input[name="material-canvas"]:checked');
    if (material) {
        let sizes = element.querySelectorAll('.product-option[data-type="size"] input[name="size"]');
        sizes.forEach((size) => { if (size.dataset.material != material.value) size.parentElement.style.display= 'none'});
    }
});