import {changeEvent, dispatchCustomEvent, fireChangeEvent} from "../../../../../webpack/modules/events";
import {slideDown, slideUp} from "../../../../../webpack/modules-optimized/element-visibility";
import {upload} from "../../../../../webpack/modules/ajax";
import {updateButtonsPrice} from "../../../../../webpack/modules-optimized/cart";

changeEvent('#product-popup .custom-file-input', true, function(element, event) {
    let group = element.closest('.input-group');
    let display = group.querySelector('input[type="text"]');
    let input = group.querySelector('input[type="hidden"]');
    let loader = group.querySelector('.loader');
    let icon = group.querySelector('.icon');
    let error = group.closest('.card').querySelector('.error');
    let file = event.target.files[0] || null;
    let prefix = element.dataset.prefix;
    let onlyPrintable = element.dataset.onlyPrintable;

    slideUp(error);

    upload(
        file,
        {prefix: prefix, onlyPrintable: onlyPrintable, file: file},
        function(response) {
            element.value = null;
            display.value = response.fileInfo.originalName;
            input.value = response.fileInfo.file;
            fireChangeEvent(input, true);
            loader.classList.add('d-none');
            icon.classList.remove('d-none');
        },
        function(message) {
            element.value = null;
            display.value = '';
            input.value = '';
            error.innerHTML = message;
            slideDown(error);
            fireChangeEvent(input, true);
            loader.classList.add('d-none');
            icon.classList.remove('d-none');
        }
    );
});

changeEvent('#product-popup input[name="customImage"]', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let customImageFromWeb = option.querySelector('.custom-image-from-web');
    let increment = option.querySelector('input[name="customPriceIncrement"]');
    let previewElement = option.querySelector('.option-preview');

    dispatchCustomEvent(
        'productCustomImageChanged',
        {
            'option': option,
            'customImageFromWeb': customImageFromWeb,
            'previewElement': previewElement,
            'increment': increment,
        }
    );
    updateButtonsPrice(container);
});

changeEvent('#product-popup input[name="customImageFromWeb"]', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let increment = option.querySelector('input[name="customPriceIncrement"]');
    let previewElement = option.querySelector('.option-preview');

    dispatchCustomEvent(
        'productCustomImageFromWebChanged',
        {
            'option': option,
            'customImageFromWeb': element,
            'previewElement': previewElement,
            'increment': increment,
            'customImage': option.querySelector('input[name="customImage"]'),
            'customImageFile': option.querySelector('input[name="file-customImage"]'),
            'customImageDisplay': option.querySelector('input[name="display-customImage"]'),
            'customText': option.querySelector('input[name="customText"], textarea[name="customText"]')
        }
    );
    updateButtonsPrice(container);
});

changeEvent('#product-popup input[name="customPriceIncrement"]', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let previewElement = option.querySelector('.option-preview');
    if (!option.querySelector('input[name="customImage"]')) return;

    dispatchCustomEvent(
        'productCustomImageIncrementChanged',
        {
            'option': option,
            'previewElement': previewElement,
            'increment': element,
            'customImage': option.querySelector('input[name="customImage"]'),
            'customImageFile': option.querySelector('input[name="file-customImage"]'),
            'customImageDisplay': option.querySelector('input[name="display-customImage"]'),
            'customImageFromWeb': option.querySelector('input[name="customImageFromWeb"]'),
        }
    );
    updateButtonsPrice(container);
});
