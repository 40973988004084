import {changeEvent, dispatchCustomEvent} from "../../../../../webpack/modules/events";
import {updateButtonsPrice, updateCartSizesPrices} from "../../../../../webpack/modules-optimized/cart";

changeEvent('#product-popup input[name="color"]', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let previewElement = option.querySelector('.option-preview');
    updateCartSizesPrices(container);
    updateButtonsPrice(container);
    dispatchCustomEvent(
        'productColorChanged',
        {
            'option': option,
            'previewElement': previewElement,
            'colorSelected': option.querySelector('input[name="color"]:checked').value,
            'orientationElement': container.querySelector(`.product-option[data-type="orientation"]`),
            'defaultOrientationElement': container.querySelector(`input[value="normal"]`)
        }
    );
});