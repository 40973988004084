import {inputEvent} from "../../../webpack/modules/events";

inputEvent('textarea.limit-rows', true, function(textarea) {
    let maxRows = textarea.dataset.maxRows;
    let rows = textarea.rows;
    let numberOfLines = getNumberOfLines(textarea);
    if (!maxRows || (rows >= maxRows && textarea.scrollHeight > textarea.clientHeight)) return;
    if (textarea.scrollHeight > textarea.clientHeight) textarea.rows = rows + 1;
    else if (numberOfLines < rows) textarea.rows = numberOfLines;
});

function getNumberOfLines(textarea)
{
    let previous_height = textarea.style.height;
    textarea.style.height = 0;
    let lines = parseInt(textarea.scrollHeight/parseInt(getComputedStyle(textarea).lineHeight) )
    textarea.style.height = previous_height;

    return lines;
}