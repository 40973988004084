import {post} from "../modules/ajax";
import {openModalFromHtml} from "./modal";
import {setCookie} from "./cookies";

export function getUserCountryData()
{
    return new Promise(resolve => {
        post(
            '/ajax/website/country/get-country-code-by-ip',
            {},
            function (data) {
                resolve(data);
            }, function () {
                resolve(null);
            }
        );
    });
}

export function showCountrySelectPopUp(countryCode)
{
    if (!countryCode) return;
    post(
        '/ajax/website/country/get-country-select-popup',
        {countryCode: countryCode},
        function(data) {
            if (!data.popUp) {
                setCookie('_tv_i18n_identified', 'y', 60);
                return;
            }

            openModalFromHtml(data.popUp, {backdrop: false});
        },
        function() {
            setCookie('_tv_i18n_identified', 'y', 60);
        }
    );
}
