import {
    dataLayerAddProduct,
    ecAddProduct,
    ecSetAction,
    ga4TrackSimpleEvent,
    sendFacebookConversionsEvent,
} from "../modules/analytics";
import {post} from "../modules/ajax";
import {fixSVG} from "../modules/svg";

export function previewOption(option, element) {
    let type = option.dataset.type;
    let preview = '';

    if (!element) return;

    switch (type) {
        case 'size':
            preview = previewSize(option);
            break;
        case 'customizer':
            preview = previewCustomizer(option);
            break;
        case 'materialColor':
        case 'color':
            preview = previewColor(option);
            break;
        case 'mechanism':
            preview = previewMechanism(option);
            break;
        default:
            let selectedOption = option.querySelector('input[type="radio"]:checked');
            if (selectedOption) preview = selectedOption.dataset.previewText || selectedOption.dataset.preview;
            break;
    }

    element.innerHTML = preview ? preview : '';
}

function previewSize(option) {
    let preview = '';
    let isMulti = option.dataset.optionType === 'multiple-selectable';
    let selectedSizes = option.querySelectorAll('input[name="size"]:checked');

    if (!selectedSizes.length) return;

    if (isMulti) preview += '<ul class="list-unstyled m-0">';

    selectedSizes.forEach(function (selectedSize) {
        let quantity = selectedSize.nextElementSibling.querySelector('input[name="quantity"], select.quantity');
        let um = option.querySelector('input[name="currentUM"]') ? option.querySelector('input[name="currentUM"]').value : selectedSize.dataset.unitSize;
        switch (selectedSize.value) {
            case 'custom-size': {
                let width = option.querySelector('.custom-width, .custom-size-width').value;
                let height = option.querySelector('.custom-height, .custom-size-height').value || option.querySelector('.custom-height, .custom-size-height').innerHTML;

                if (isMulti) preview += '<li>';
                if (width && height) preview += width + ' x ' + height + ' ' + um;
                if (quantity && !quantity.classList.contains('disabled')) preview += '&nbsp; &nbsp; x ' + quantity.value;
                if (isMulti) preview += '</li>';
                break;
            }
            case 'both-size': {
                let width = option.querySelector('input[name="bothWidth"]').value;
                let height = option.querySelector('input[name="bothHeight"]').value;

                if (isMulti) preview += '<li>';
                if (width && height) preview += width + ' ' + um + ' x ' + height + ' ' + um;
                if (quantity && !quantity.classList.contains('disabled')) preview += '&nbsp; &nbsp; x ' + quantity.value;
                if (isMulti) preview += '</li>';
                break;
            }
            case 'presets-size': {
                let width = option.querySelector('input[name="presetsWidth"]:checked');
                let height = option.querySelector('input[name="presetsHeight"]:checked');

                if (isMulti) preview += '<li>';
                if (width && height) preview += width.value + ' ' + um + ' x ' + height.value + ' ' + um;
                if (quantity && !quantity.classList.contains('disabled')) preview += '&nbsp; &nbsp; x ' + quantity.value;
                if (isMulti) preview += '</li>';
                break;
            }
            default:
                if (isMulti) preview += '<li>';
                preview += selectedSize.dataset.previewText || '';
                if (quantity && !quantity.classList.contains('disabled')) preview += '&nbsp; &nbsp; x ' + quantity.value;
                if (!quantity && selectedSize.dataset.quantity) preview += '&nbsp; &nbsp; x ' + selectedSize.dataset.quantity;
                if (isMulti) preview += '</li>';
                break;
        }
    });

    if (isMulti) preview += '</ul>';

    return preview;
}

function previewCustomizer(option) {
    let preview = '';
    let customImage = option.querySelector('input[name="display-customImage"], input[name="display-custom-image"]');
    let customImageFromWeb = option.querySelector('input[name="customImageFromWeb"]');
    let customText = option.querySelector('input[name="customText"], textarea[name="customText"]');

    if (option.closest('#product-popup')) {
        if ((customImage && customImage.value) || (customImageFromWeb && customImageFromWeb.checked)) preview = '<i class="fa-solid fa-camera me-1"></i>';
        if (customText && customText.value) preview += customText.value;
    } else {
        if ((customImage || customImageFromWeb) && customText) {
            if ((customImage.value === '' && (!customImageFromWeb || !customImageFromWeb.checked)) || customText.value === '') return '';
            if (customText && customText.value) preview += customText.dataset.preview + ': ' + customText.value + '<br>';
            if (customImage && customImage.value !== '') preview += customImage.dataset.preview + ': ' + customImage.value;
            if (customImageFromWeb && customImageFromWeb.checked) preview += customImage.dataset.preview + ': ' + customImageFromWeb.dataset.preview;
        } else {
            if (customImage && customImage.value !== '') preview = customImage.value;
            if (customImageFromWeb && customImageFromWeb.checked) preview = customImageFromWeb.dataset.preview;
            if (customText && customText.value) preview = customText.value;
        }
    }

    return preview;
}

function previewColor(option) {
    let preview = '';
    let selectedColor = option.querySelector('input[name="color"]:checked, input[name="materialColor"]:checked');

    if (selectedColor) {
        let previewColor = selectedColor.dataset.previewColor;
        let previewText = selectedColor.dataset.previewText;
        let previewName = selectedColor.dataset.previewName;

        if (previewText) preview = '<div class="preview-color" style="background: ' + previewColor + '" title="' + previewText + '"></div>';
        if (previewName) {
            preview += '<span class="display-color d-inline-block align-middle rounded-circle border border-dark" style="background: ' + previewColor + '" title="' + previewName + '"></span>';
            preview += '<span class="align-middle"> ' + previewName + '</span>'
        }
    }

    return preview;
}

function previewMechanism(option) {
    let preview = '';
    let selectedMechanism = option.querySelector('input[name="mechanism"]:checked');

    if (selectedMechanism) preview = selectedMechanism.dataset.previewText;

    return preview;
}


let ajaxCalculatePrice = null;
let ajaxCalculateMultiSizePrice = null;

export function calculatePrice(container, quantityFixed = null, optionsFixed = null, fixedProductId = null, enableWarning = false) {
    return new Promise(resolve => {
        let productId = fixedProductId || container.dataset.productId;
        let quantity = quantityFixed || getQuantity(container);
        let options = optionsFixed || getOptions(container);

        if (isMultiSizeProduct(container)) {
            let products = getMultiSizeProducts(container);
            if (!products.length) return resolve([null, null]);
            if (ajaxCalculateMultiSizePrice) ajaxCalculateMultiSizePrice.abort();
            ajaxCalculateMultiSizePrice = post(
                '/ajax/website/product/calculate-multi-size-price',
                {products: products},
                function (response) {
                    ajaxCalculatePrice = null;
                    if (response.error) return resolve([null, null]);
                    let priceDecimals = response.price % 1 ? response.price.toString().split(".")[1].length : 0;
                    let price = parseFloat(response.price);
                    let premiumPrice = parseFloat(response.premiumPrice);

                    if (enableWarning) resolve({'price': price.toFixed(priceDecimals), 'premiumPrice': premiumPrice.toFixed(priceDecimals), 'warning': response.warning});
                    else resolve([price.toFixed(priceDecimals), premiumPrice.toFixed(priceDecimals)]);
                },
                function () {
                    ajaxCalculatePrice = null;
                    return resolve([null, null]);
                }
            );
        } else {
            if (!validOptions(options)) return resolve([null, null]);
            if (ajaxCalculatePrice) ajaxCalculatePrice.abort();
            ajaxCalculatePrice = post(
                '/ajax/website/product/calculate-price',
                {
                    productId: productId,
                    quantity: quantity,
                    options: options
                },
                function (response) {
                    ajaxCalculatePrice = null;
                    if (response.error) return resolve([null, null]);

                    let priceDecimals = response.price % 1 ? response.price.toString().split(".")[1].length : 0;
                    let price = response.price * quantity;
                    let premiumPrice = response.premiumPrice * quantity;

                    if (enableWarning) resolve({'price': price.toFixed(priceDecimals), 'premiumPrice': premiumPrice.toFixed(priceDecimals), 'warning': response.warning});
                    else resolve([price.toFixed(priceDecimals), premiumPrice.toFixed(priceDecimals)]);
                },
                function () {
                    ajaxCalculatePrice = null;
                    if (enableWarning) resolve({'price': null, 'premiumPrice': null, 'warning': null});
                    else resolve([null, null]);
                }
            );
        }
    });
}


let ajaxCalculateCustomSizePrice = null;
export function calculateCustomSizePrice(productId, width, height, pack)
{
    return new Promise(resolve => {
        if (ajaxCalculateCustomSizePrice) ajaxCalculateCustomSizePrice.abort();
        ajaxCalculateCustomSizePrice = post(
            '/ajax/website/product/calculate-price',
            {
                productId: productId,
                quantity: 1,
                options: {width: width, height: height, pack: pack, personalizedSize: true}
            },
            function (response) {
                ajaxCalculateCustomSizePrice = null;
                let priceDecimals = response.price % 1 ? response.price.toString().split(".")[1].length : 0;
                let price = parseFloat(response.price);
                resolve({'price': price.toFixed(priceDecimals), 'warning': response.warning});
            },
            function () {
                ajaxCalculateCustomSizePrice = null;
                resolve({'price': null, 'premiumPrice': null, 'warning': null});
            }
        );
    });
}

export function getQuantity(container) {
    let input = 1;

    container.querySelectorAll('.quantity-add-to-cart').forEach(function (element) {
        if (element.value > input) input = element.value;
    });

    return input;
}

export function trackProductPageGa4Events(event, extraData) {
    let richCard = document.querySelector('script[type="application/ld+json"]');
    let sku = '';
    let name = '';
    let productType = '';
    if (richCard) {
        let jsonld = JSON.parse(richCard.innerText);
        sku = jsonld ? jsonld.sku || '' : '';
        name = jsonld ? jsonld.name || '' : '';
        productType = (jsonld ? jsonld.category || '' : '').toLowerCase();
    } else {
        let popup = document.getElementById('product-popup');
        sku = popup ? popup.dataset.productReference : '';
        name = popup ? popup.dataset.productName : '';
        // TODO: SET ON NEW LISTINGs
        productType = ''; //popup ? popup.dataset.productType : '';
    }
    switch (event) {
        case 'Frame':
            ga4TrackSimpleEvent({
                "event": 'select_frame',
                "color": extraData,
                "product_id": sku,
                "product_name": name
            });
            break;
        case 'ImageZoom':
            ga4TrackSimpleEvent({
                "event": 'image_zoom',
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'SampleImage':
            ga4TrackSimpleEvent({
                "event": 'sample_image',
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'Complement':
            ga4TrackSimpleEvent({
                "event": 'select_complement',
                "complement": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'complement',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'CrossSelling':
            ga4TrackSimpleEvent({
                "event": 'select_cross_selling',
                "complement": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'cross_selling',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'ProductInformation':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'product_information',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'AddToCartPopup':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'add_to_cart_popup',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'AddToCart':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'add_to_cart',
                "product_type": productType
            });
            break;
        case 'AddToCartFromSizes':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "method": 'add_to_cart',
                "product_type": productType
            });
            break;
        case 'AddToCartError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "event_type": extraData,
                "method": 'add_to_cart_error',
                "product_type": productType
            });
            break;
        case 'ContinueConfiguration':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "method": 'continue_configuration',
                "product_type": productType
            });
            break;
        case 'UnitSelector':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'unit_selector',
                "product_type": productType
            });
            break;
        case 'CustomSizeConfirm':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'custom_size_confirm',
                "product_type": productType
            });
            break;
        case 'CustomSize':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'custom_size',
                "product_type": productType
            });
            break;
        case 'OpenProductOffCanvas':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'open',
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'GlassReversed':
            if (extraData === 'add') {
                ga4TrackSimpleEvent({
                    "event": 'select_glass_reversed',
                    "product_id": sku,
                    "product_type": productType,
                    "product_name": name
                });
            } else {
                ga4TrackSimpleEvent({
                    "event": 'unselect_glass_reversed',
                    "product_id": sku,
                    "product_type": productType,
                    "product_name": name
                });
            }
            break;
        case 'Size':
            ga4TrackSimpleEvent({
                "event": 'select_size',
                "size": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'Color':
            ga4TrackSimpleEvent({
                "event": 'select_color',
                "color": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'WidthError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "width_error",
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'HeightError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "height_error",
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'OpenProductOptionInfo':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "open_more_info",
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'CloseProductOptionInfo':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "close_more_info",
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'BlindTissue':
            ga4TrackSimpleEvent({
                "event": 'select_blind_tissue',
                "tissue": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'BlindChain':
            ga4TrackSimpleEvent({
                "event": 'select_blind_chain',
                "chain": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'BlindDrop':
            ga4TrackSimpleEvent({
                "event": 'select_blind_drop',
                "drop": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'Model':
            ga4TrackSimpleEvent({
                "event": 'select_model',
                "model": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'ModelSize':
            ga4TrackSimpleEvent({
                "event": 'select_model_size',
                "model_size": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'MaterialColor':
            ga4TrackSimpleEvent({
                "event": 'select_material_color',
                "material_color": extraData,
                "product_id": sku,
                "product_type": productType,
                "product_name": name
            });
            break;
        case 'OpenModelSizeInformation':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "open_model_size_info",
                "product_type": productType,
            });
            break;
        case 'CloseModelSizeInformation':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "close_model_size_info",
                "product_type": productType,
            });
            break;
        case 'SwitchMultiProduct':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": "close_model_size_info",
                "product_type": productType,
                "content_type": extraData
            });
            break;
        case 'AddToCartMultiProductError':
            ga4TrackSimpleEvent({
                "event": 'product_page',
                "method": 'add_to_cart_multi_product_error',
                "product_type": productType,
                "content_type": extraData
            });
            break;
    }
}

export function getOptions(container) {
    let options = [];
    options['multi'] = [];
    if (document.referrer.indexOf(location.protocol + "//" + location.host) === 0) options['referrer'] = document.referrer;

    container.querySelectorAll('.product-option, .options, .option-single-value').forEach(function (option) {
        if (option.classList.contains('has-error') && option.dataset.optionType !== 'multiple-selectable') return;

        let index = 1;
        if (option.closest('.product-options')) index = option.closest('.product-options').dataset.index || 1;
        if (option.closest('.options')) index = option.closest('.options').dataset.productIndex || 1;
        if (index > 1 && !options['multi'][index]) options['multi'][index] = [];

        switch (option.dataset.type) {
            case 'size':
                addSizeOption(option, index, options);
                break;
            case 'customizer':
                addCustomizeOption(option, index, options);
                break;
            case 'color':
                addColorOption(option, index, options);
                break;
            case 'pack':
                addPackOption(option, index, options);
                break;
            case 'orientation':
                addOrientationOption(option, index, options);
                break;
            case 'glass-reversed':
                addGlassReversedOption(option, index, options);
                break;
            case 'blind-tissue':
            case 'tissue':
                addTissueOption(option, index, options);
                break;
            case 'blind-chain':
            case 'mechanism':
                addMechanismOption(option, index, options);
                break;
            case 'blind-drop':
            case 'drop':
                addDropOption(option, index, options);
                break;
            case 'blind-support-color':
            case 'support-color':
                addSupportColorOption(option, index, options);
                break;
            case 'model':
                addModelOption(option, index, options);
                break;
            case 'material-color':
            case 'materialColor':
                addMaterialColorOption(option, index, options);
                break;
            case 'material-canvas':
                addMaterialCanvasOption(option, index, options);
                break;
            case 'model-size':
            case 'modelSize':
                addModelSizeOption(option, index, options);
                break;
            case 'poster-frame':
                addFrameOption(option, index, options);
                break;
            case 'vinyl-rug-format':
                addFormatOption(option, index, options);
                break;
            case 'hidden':
                addHiddenOption(option, index, options);
                break;
        }
    });

    return options;
}

function addSizeOption(container, index, options) {
    let selectedSize = container.querySelector('input[name="size"]:checked, input[name="size"][type="hidden"]');

    if (!selectedSize) return;

    switch (selectedSize.value) {
        case 'custom-size':
            addOption('width', container.querySelector('.custom-width, .custom-size-width').value, index, options);
            addOption('height', container.querySelector('.custom-height, .custom-size-height').value || container.querySelector('.custom-height, .custom-size-height').innerHTML, index, options);
            addOption('personalizedSize', true, index, options);
            break;
        case 'both-size':
            addOption('width', container.querySelector('[name="bothWidth"]').value, index, options);
            addOption('height', container.querySelector('[name="bothHeight"]').value, index, options);
            addOption('personalizedSize', true, index, options);
            break;
        default:
            if (selectedSize.dataset.device) {
                addOption('device', selectedSize.dataset.device, index, options);
            } else if (selectedSize.dataset.composition) {
                addOption('composition', selectedSize.dataset.composition, index, options);
            } else {
                addOption('width', selectedSize.dataset.width, index, options);
                addOption('height', selectedSize.dataset.height, index, options);
            }
            addOption('personalizedSize', false, index, options);
            break;
    }
}

function addCustomizeOption(container, index, options) {
    let customImage = container.querySelector('input[name="customImage"], input[name="custom-image"]');
    let customImageText = container.querySelector('input[name="display-customImage"], input[name="display-custom-image"]');
    let customImageFromWeb = container.querySelector('.custom-image-from-web, input[name="customImageFromWeb"]');
    let customText = container.querySelector('textarea[name="customText"], input[name="customText"]');
    let customPriceIncrement = container.querySelector('input[name="customPriceIncrement"]');
    let personalizedSvg = container.querySelector('input[name="personalizedSvg"]');
    let personalizedImagePreview = container.querySelector('input[name="personalizedImagePreview"]');
    let svgPersonalizedText = container.querySelector('input[name="svg-personalized-text"], input#svg-personalized-text');
    let customTextValue = customText ? customText.value : null;

    if (customPriceIncrement) {
        if (customText && (customImage || customImageFromWeb)) {
            customTextValue = customText.value !== '' ? customText.value : '-';
        } else if (customText) {
            if (!customPriceIncrement.checked) customTextValue = '-';
        }
    }

    addOption('personalizedImageFromWeb', customImageFromWeb ? customImageFromWeb.checked : false, index, options);
    addOption('personalizedImage', customImage ? customImage.value : null, index, options);
    addOption('personalizedImageText', customImageText ? customImageText.value : null, index, options);
    addOption('personalizedText', customTextValue, index, options);
    addOption('personalizedSvg', personalizedSvg ? convertSvgToBase64(personalizedSvg.value) : null, index, options);
    addOption('personalizedImagePreview', personalizedImagePreview ? personalizedImagePreview.value : null, index, options);

    if (svgPersonalizedText) {
        if (svgPersonalizedText.dataset.svg) addOption('personalizedImage', svgPersonalizedText.dataset.svg, index, options);
        if (svgPersonalizedText.dataset.preview) addOption('personalizedImagePreview', svgPersonalizedText.dataset.preview, index, options);
    }
    if (customPriceIncrement && customPriceIncrement.checked && (options['personalizedText'] || options['personalizedImage'])) {
        addOption('customPriceIncrement', true, index, options);
    }
}

function addColorOption(container, index, options) {
    let selectedColor = container.querySelector('input[name="color"]:checked, input[name="color"][type="hidden"].hidden-option');

    if (!selectedColor) return;

    addOption('color', selectedColor.value, index, options);
}

function addPackOption(container, index, options) {
    let selectedPack = container.querySelector('input[type="radio"]:checked');

    if (!selectedPack) return;

    let pack = selectedPack.value;

    if (pack === 'custom-pack') pack = container.querySelector('[name="customPack"]').value;

    addOption('pack', pack, index, options);
}

function addOrientationOption(container, index, options) {
    let selectedOrientation = container.querySelector('input[name="orientation"]:checked');

    if (!selectedOrientation) return;

    addOption('mirrored', selectedOrientation.value === 'mirrored', index, options);
}

function addGlassReversedOption(container, index, options) {
    let selectedGlassReversed = container.querySelector('input[name="glass-reversed"]:checked');

    if (!selectedGlassReversed) return;

    addOption('reversed', true, index, options);
}

function addTissueOption(container, index, options) {
    let selectedTissue = container.querySelector('input[name="tissue"]:checked');

    if (!selectedTissue) return;

    addOption('tissue', selectedTissue.value, index, options);
}

function addMechanismOption(container, index, options) {
    let selectedMechanism = container.querySelector('input[name="mechanism"]:checked, input[type="hidden"][name="mechanism"]');
    let selectedChainPosition = container.querySelector('input[name="chainPosition"]:checked');

    if (!selectedMechanism) return;

    addOption('mechanism', selectedMechanism.value, index, options);
    if (selectedChainPosition) addOption('chainPosition', selectedChainPosition.value, index, options);
}

function addDropOption(container, index, options) {
    let selectedDrop = container.querySelector('input[name="drop"]:checked');

    if (!selectedDrop) return;

    addOption('drop', selectedDrop.value, index, options);
}

function addSupportColorOption(container, index, options) {
    let selectedSupportColor = container.querySelector('input[name="support-color"]:checked');

    addOption('supportColor', selectedSupportColor ? selectedSupportColor.value : '', index, options);
}

function addModelOption(container, index, options) {
    let selectedModel = container.querySelector('input[name="model"]:checked');

    if (!selectedModel) return;

    addOption('model', selectedModel.value, index, options);
}

function addMaterialColorOption(container, index, options) {
    let selectedMaterialColor = container.querySelector('input[name="materialColor"]:checked, input[name="material-color"]:checked');

    if (!selectedMaterialColor) return;

    addOption('materialColor', selectedMaterialColor.value, index, options);
}

function addMaterialCanvasOption(container, index, options) {
    let selectedMaterialCanvas = container.querySelector('input[name="material-canvas"]:checked, input[name="material-canvas"][type="hidden"]');

    addOption('materialCanvas', selectedMaterialCanvas ? selectedMaterialCanvas.value : '', index, options);
}

function addModelSizeOption(container, index, options) {
    let selectedModelSize = container.querySelector('input[name="modelSize"]:checked, input[name="model-size"]:checked');

    if (!selectedModelSize) return;

    addOption('modelSize', selectedModelSize.value, index, options);
}

function addFrameOption(container, index, options) {
    let selectedFrame = container.querySelector('input[name="poster-frame"]:checked');

    addOption('frame', selectedFrame ? selectedFrame.value : '', index, options);
}

function addFormatOption(container, index, options) {
    let selectedFormat = container.querySelector('input[name="vinyl-rug-format"]:checked, input[name="vinyl-rug-format"][type="hidden"]');

    addOption('format', selectedFormat ? selectedFormat.value : '', index, options);
}

function addHiddenOption(container, index, options) {
    container.querySelectorAll('input').forEach(function (input) {
        addOption(input.name, input.value, index, options);
    });
}

function validOptions(options) {
    return validSizeOptions(options) && validPackOption(options);
}

function validSizeOptions(options) {
    let valid = !(((!options['width'] || !parseFloat(options['width'])) || (!options['height'] || !parseFloat(options['height']))) &&
        !options['device'] &&
        !options['composition'] &&
        !options['model']);

    if (valid && options['multi'] && options['multi'].length) {
        options['multi'].forEach(function (multiProductOptions) {
            valid = validSizeOptions(multiProductOptions);
            if (!valid) {
                return false;
            }
        });
    }

    return valid;
}

function validPackOption(options) {
    return !('pack' in options && !options['pack']);
}

export function getAddToAllComplements(container) {
    let complements = [];
    container.querySelectorAll('.product-complements[data-add-to-all-sizes="true"]:checked, .product-complement[data-add-to-all-sizes="true"]:checked').forEach(function (complement) {
        complements.push(
            {
                id: complement.value,
                price: complement.dataset.price,
                quantity: complement.dataset.quantity,
                isLocked: complement.dataset.locked,
                linkToParent: complement.dataset.linkToParent,
                options: {
                    width: complement.dataset.width,
                    height: complement.dataset.height,
                    device: complement.dataset.device,
                    composition: complement.dataset.composition
                }
            }
        );
    });

    return complements;
}

export function getComplements(container) {
    let complements = [];

    container.querySelectorAll('.product-complements:checked, .product-complement:checked').forEach(function (complement) {
        if (complement.dataset.alreadyAdded) return;

        if (complement.id === 'checkbox-up-selling-personalized-text') {
            let svg = convertSvgToBase64(complement.dataset.svg);
            complements.push(
                {
                    id: complement.value,
                    price: complement.dataset.price,
                    quantity: complement.dataset.quantity,
                    linkToParent: complement.dataset.linkToParent,
                    options: {
                        width: complement.dataset.width,
                        height: complement.dataset.height,
                        personalizedText: complement.dataset.text,
                        font: complement.dataset.font,
                        mirrored: complement.dataset.mirrored,
                        color: complement.dataset.color,
                        personalizedSvg: svg,
                        rightToLeft: complement.dataset.rightToLeft,
                        isUpselling: true,
                        personalizedSize: true
                    }
                }
            );
        } else {
            complements.push(
                {
                    id: complement.value,
                    price: complement.dataset.price,
                    quantity: complement.dataset.quantity,
                    isLocked: complement.dataset.locked,
                    linkToParent: complement.dataset.linkToParent,
                    options: {
                        width: complement.dataset.width,
                        height: complement.dataset.height,
                        device: complement.dataset.device,
                        composition: complement.dataset.composition
                    }
                }
            );
        }
    });

    return complements;
}

export async function updateButtonsPrice(container) {
    let buttons = container.querySelectorAll('.btn-add-to-cart');
    buttons.forEach((button) => showButtonLoader(button));

    await calculatePrice(container);
    buttons.forEach((button) => hideButtonLoader(button));
}

function showButtonLoader(button) {
    let loader = button.querySelector('.loader');
    let elementToHide = button.querySelector('.text');

    button.disabled = true;
    if (elementToHide) elementToHide.classList.add('d-none');
    if (loader) loader.classList.remove('d-none');
}

function hideButtonLoader(button) {
    let loader = button.querySelector('.loader');
    let elementToHide = button.querySelector('.text');

    button.disabled = false;
    if (loader) loader.classList.add('d-none');
    if (elementToHide) elementToHide.classList.remove('d-none');
}

export function getDataAddToCart(container) {
    let productId = container.dataset.productId;
    let parentId = container.dataset.parentId;
    let quantity = getQuantity(container);
    let options = getOptions(container);
    let complements = getComplements(container);
    let crossSellingProducts = getCrossSellingProducts(container);
    let isLocked = lockItem(container);

    return {
        productId: productId,
        parentId: parentId,
        quantity: quantity,
        options: options,
        isLocked: isLocked,
        extraProducts: complements.concat(crossSellingProducts)
    }
}

export function getCrossSellingProducts(container) {
    let products = [];
    let productId = container.dataset.productId;
    let crossSellingProducts = container.querySelectorAll('input[name="cross-selling-product"]:checked, .product-cross-selling:checked');

    crossSellingProducts.forEach(function (product) {
        if (product.dataset.alreadyAdded) return;

        products.push(
            {
                id: product.value,
                price: product.dataset.price,
                quantity: product.dataset.quantity,
                linkToParent: product.dataset.linkToParent,
                options: {
                    width: product.dataset.width,
                    height: product.dataset.height,
                    device: product.dataset.device,
                    composition: product.dataset.composition,
                    originalProduct: productId,
                    color: product.dataset.color,
                    personalizedImage: product.dataset.image,
                    pack: product.dataset.pack
                }
            }
        );
    });

    return products;
}

export function getDataToRemoveFromCart(container) {
    let complements = getPreviouslyAddedComplementsToRemove(container);
    let crossSellingProducts = getPreviouslyAddedCrossSellingProductsToRemove(container);

    return complements.concat(crossSellingProducts);
}

function getPreviouslyAddedComplementsToRemove(container) {
    let productComplements = container.querySelectorAll('.product-complement');
    let toRemove = [].filter.call(productComplements, function (el) {
        return !el.checked && el.dataset.alreadyAdded;
    });

    return toRemove;
}

function getPreviouslyAddedCrossSellingProductsToRemove(container) {
    let crossSellingProducts = container.querySelectorAll('input[name="cross-selling-product"]');
    let toRemove = [].filter.call(crossSellingProducts, function (el) {
        return !el.checked && el.dataset.alreadyAdded;
    });

    return toRemove;
}

let ajaxProportionalHeight = null;

export function getProportionalHeight(productId, width, factor) {
    if (ajaxProportionalHeight) ajaxProportionalHeight.abort();

    return new Promise(resolve => {
        ajaxProportionalHeight = post(
            '/ajax/website/product/get-proportional-height',
            {
                width: width,
                productId: productId,
                factor: factor,
            },
            function (response) {
                resolve({'error': false, 'height': response.height});
            },
            function (response) {
                resolve({'error': true, 'message': response.sizesMessage});
            }
        );
    });
}

function addOption(name, value, index, options) {
    if (index == 1) options[name] = value;
    else options['multi'][index][name] = value;
}

let ajaxPreviewImage = null;

export function getPreviewImage(container, productIndex) {
    let productId = container.dataset.productId;
    let options = getOptions(container);

    if (ajaxPreviewImage) ajaxPreviewImage.abort();

    return new Promise(resolve => {
        ajaxPreviewImage = post(
            '/ajax/website/product/load-preview-image',
            {
                productId: productId,
                options: options,
                photomontage: false,
                productIndex: productIndex,
            },
            function (response) {
                resolve(response.image);
            },
            function () {
                resolve(null);
            }
        );
    });
}

export function getSvgPreviewImage(productId, text, color) {
    return new Promise(resolve => {
        post(
            '/ajax/website/product/get-svg-personalized-text',
            {
                id: productId,
                text: text,
                color: color,
            },
            function (response) {
                resolve({'svg': response.svg, 'svgPreview': response.svgPreview});
            },
            function () {
                resolve({'svg': null, 'svgPreview': null});
            }
        );
    });
}

export function isMultiSizeProduct(container) {
    return container ? container.querySelectorAll('input[name="size"]:checked').length > 1 : false;
}

export function getMultiSizeProducts(container) {
    let products = [];
    let selectedSizes = container.querySelectorAll('.options[data-type="size"] .option-value:checked');
    let productId = container.dataset.productId;
    let parentId = container.dataset.parent;

    selectedSizes.forEach(function (size, index) {
        let width = size.dataset.width;
        let height = size.dataset.height;
        let device = size.dataset.device;
        let composition = size.dataset.composition;
        let quantity = size.dataset.quantity;
        let options = getOptions(container);
        options['width'] = width;
        options['height'] = height;
        options['device'] = device || '';
        options['composition'] = composition || '';

        let product = {
            productId: productId,
            parentId: parentId,
            quantity: quantity,
            options: options,
        };
        let protectiveFilmSelected = lockItem(container);
        product['isLocked'] = protectiveFilmSelected;

        if (index === selectedSizes.length - 1) {
            let complements = getComplements(container);
            let crossSellingProducts = getCrossSellingProducts(container);
            product['extraProducts'] = complements.concat(crossSellingProducts);
        } else if (protectiveFilmSelected) {
            product['extraProducts'] = getAddToAllComplements(container);
        }

        if (validOptions(options)) products.push(product);
    });

    return products;
}

export function lockItem(container) {
    let protectiveFilm = container.querySelector('.product-complements[data-add-to-all-sizes="true"]:checked');
    return !!protectiveFilm;
}

let ajaxSizesPrices = null;
export function getSizesPrices(container) {
    let productId = container.dataset.productId;
    let options = getOptions(container);

    if (ajaxSizesPrices) ajaxSizesPrices.abort();

    return new Promise(resolve => {
        ajaxSizesPrices = post(
            '/ajax/website/product/update-sizes-prices',
            {
                productId: productId,
                options: options
            },
            function (response) {
                ajaxSizesPrices = null;
                resolve(response.prices);
            },
            function () {
                ajaxSizesPrices = null;
                resolve(null);
            }
        );
    });
}

let ajaxSizesPricesByPack = null;
export function getSizesPricesByPack(productId, pack) {
    if (ajaxSizesPricesByPack) ajaxSizesPricesByPack.abort();

    return new Promise(resolve => {
        ajaxSizesPricesByPack = post(
            '/ajax/website/product/update-pack-prices',
            {
                productId: productId,
                pack: pack
            },
            function (response) {
                ajaxSizesPricesByPack = null;
                resolve(response.prices);
            },
            function () {
                ajaxSizesPricesByPack = null;
                resolve(null);
            }
        );
    });
}

export function sendEventsAddToCart(items) {
    let fbqData = {
        content_type: 'product',
        content_name: '',
        content_ids: [],
        contents: [],
        value: 0,
        currency: ''
    };

    items.forEach(function (item) {
        dataLayerAddProduct(item);
        ecAddProduct(item.productId, item.productName, item.price, item.quantity, item.color, item.size, item.orientation);
        ecSetAction('add');
        if (window.fbq) {
            if (!fbqData.content_name) {
                fbqData.content_name = item.productName;
                fbqData.currency = item.currency;
            }
            fbqData.content_ids.push(item.reference);
            fbqData.value = fbqData.value + (item.price * item.quantity);
            fbqData.contents.push({id: item.reference, quantity: item.quantity});
        }
        if (window.pintrk) {
            pintrk('track', 'AddToCart', {
                value: item.price,
                order_quantity: item.quantity,
                currency: item.currency
            });
        }
    });

    if (window.fbq) {
        fbq('track', 'AddToCart', fbqData);
        sendFacebookConversionsEvent('AddToCart', fbqData);
    }
}

export function fixPersonalizedSVG(personalizedSVG, container) {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = personalizedSVG;
    let svg = tempDiv.querySelector('svg');
    fixSVG(svg);
    let viewBox = svg.getAttribute('viewBox').split(' ');
    let factor = container.querySelector('input[name="factor"]');
    if (factor) factor.value = viewBox[3] / viewBox[2];

    return tempDiv.innerHTML;
}

let ajaxSendCalculateMultiPrice = null;

export function ajaxCalculateMultiPrices(data) {
    if (ajaxSendCalculateMultiPrice) ajaxSendCalculateMultiPrice.abort();
    return new Promise((resolve) => {
        ajaxSendCalculateMultiPrice = post(
            '/ajax/website/product/calculate-multiple-prices',
            {
                data: data
            },
            function (response) {
                resolve(response);
            }
        );
    });
}

export function convertSvgToBase64(svg){
    if (svg === '') return '';
    else return "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svg)));
}