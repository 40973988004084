import {clickEvent, customEvent, fireClickEvent, mouseLeaveElementEvent} from "../../webpack/modules/events";
import {sendEmail, showPopup} from "../../webpack/modules-optimized/popup-smarter-click";
import {sendPopupEvent} from "../../../library/analytics";
import {getModal} from "../../webpack/modules-optimized/modal";
import {applyVoucher} from "../../webpack/modules-optimized/voucher";
import {hideLoader, renderCart, showLoader} from "../../webpack/modules-optimized/cart";
import {isMobile, isTablet} from "../../webpack/modules/devices";

setTimeout(function () {
    mouseLeaveElementEvent(document, function(element, event) {
        let productFinder = document.getElementById('product-finder');
        if (productFinder && productFinder.classList.contains('pf-open')) return;
        let windowHeight = window.innerHeight;
        if (event.offsetY < 0 || event.offsetY >= windowHeight) showPopup();
    });

    if (isTablet() || isMobile()) {
        window.addEventListener('blur', function(){
            let productFinder = document.getElementById('product-finder');
            if (productFinder && productFinder.classList.contains('pf-open')) return;
            showPopup();
        });
    }
}, 30000); // wait 30s before show any popup for the first time

clickEvent('.close-pop-up-smarter-click', true, function(element) {
    let popupAction = element.dataset.popupType || 'pop-up';
    sendPopupEvent('popup_discount', popupAction, 'cancel');
});

clickEvent('#approve-pop-up-smarter-click', true, async function(element) {
    let popupAction = element.dataset.popupType || 'pop-up';
    let popupType = document.getElementById('pop-up-smarter-click').value;
    let voucherCode = document.getElementById('pop-up-smarter-click-voucher-code').value;
    let privacyElement = document.getElementById('pop-up-smarter-click-privacy');
    let email = document.getElementById('pop-up-smarter-click-email');
    let cart = document.getElementById('cart');

    if (popupType === 'POP UP EMAIL') {
        if (email.value === '') {
            email.classList.add('error');
            return;
        }

        if (!privacyElement.checked) {
            privacyElement.classList.add('error');
            return;
        }

        sendEmail(email);
        getModal('modal-pop-up-smarter-click').hide();
    } else {
        getModal('modal-pop-up-smarter-click').hide();
        await applyVoucher(voucherCode);

        if (!cart.classList.contains('show')) fireClickEvent(document.querySelector('header .open-cart'));

        showLoader();
        await renderCart();
        hideLoader();
    }

    sendPopupEvent('popup_discount', popupAction, 'approve');
});

customEvent('hidden.bs.modal', '#modal-pop-up-smarter-click', true, function() {
    document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
});
