import {customDocumentEvent} from "../../../webpack/modules/events";
import {previewOption} from "../../../webpack/modules-optimized/product";

customDocumentEvent('productColorChanged', function(event) {
    let option = event.detail.option;
    let previewElement = event.detail.previewElement;
    let colorSelected = event.detail.colorSelected;
    let orientationElement = event.detail.orientationElement;
    let defaultOrientationElement = event.detail.defaultOrientationElement;
    let glassReversedElement = event.detail.glassReversedElement;

    /*console.log(colorSelected);
    console.log(orientationElement);
    console.log(defaultOrientationElement);*/

    previewOption(option, previewElement);
    hiddenOrientation(colorSelected, orientationElement, defaultOrientationElement);
    hiddenGlassReversed(colorSelected, glassReversedElement);
});

function hiddenOrientation(selectedColor, orientation, defaultElement)
{
    if (!selectedColor || !orientation || !defaultElement) return;

    if (selectedColor == 104) {
        defaultElement.checked = true;
        if (!orientation.classList.contains('d-none')) orientation.classList.add('d-none');
    } else {
        orientation.classList.remove('d-none');
    }
}

function hiddenGlassReversed(selectedColor, glassReversed)
{
    if (!selectedColor || !glassReversed) return;

    if (selectedColor == 104) {
        let inputElement = glassReversed.querySelector('input[name="glass-reversed"]');
        if (inputElement) inputElement.checked = false;
        if (!glassReversed.classList.contains('d-none')) glassReversed.classList.add('d-none');
    } else {
        glassReversed.classList.remove('d-none');
    }
}