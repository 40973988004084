import {get} from "../modules/ajax";
import {hideModalLoader, openModalFromHtml, showModal, showModalLoader} from "./modal";
import Splide from "@splidejs/splide";
import {getImageSizeFromUrl} from "./image";
import {hideElement, showElement} from "./element-visibility";
import {isDesktop, isIOS} from "../modules/devices";
import PinchZoom from "pinch-zoom-js";

const $body = document.querySelector('body');
const $html = document.querySelector('html');
let scrollPosition = 0;
let splide = null;

export function addIOSWindowFix()
{
    $html.style.height = 'calc(100vh - 1px)';
    scrollPosition = window.pageYOffset;
    $body.style.overflowY = 'hidden';
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollPosition}px`;
    $body.style.width = '100%';
}

export function clearIOSWindowFix()
{
    $body.style.removeProperty('overflowY');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
    $html.style.removeProperty('height');
}

export function showImageZoomPopup(productId, position)
{
    if (!isDesktop() && isIOS()) addIOSWindowFix();

    let modal = document.getElementById('modal-image-zoom');
    if (modal) {
        showModalLoader(modal);
        if (modal.dataset.productId === productId) {
            if (isDesktop()) removeZoomOfAllDesktopImages();
            splide.go(position);
            hideModalLoader(modal);
        } else {
            getModalImageZoom(productId, true, position);
        }
        showModal('modal-image-zoom');
    } else {
        getModalImageZoom(productId, false, position);
    }
}

let ajaxModal = null;
function getModalImageZoom(productId, onlyContent, position)
{
    if (ajaxModal) ajaxModal.abort();
    ajaxModal = get(
        '/ajax/website/product/get-modal-image-zoom',
        {
            productId: productId,
            onlyContent: onlyContent,
        },
        function(response) {
            ajaxModal = null;
            if (onlyContent) {
                let modal = document.getElementById('modal-image-zoom');
                modal.dataset.productId = productId;
                modal.querySelector('#modal-image-zoom-content').innerHTML = response.modal;
                hideModalLoader(modal);
            } else {
                openModalFromHtml(response.modal, {}, false);
            }
            setTimeout(function(){
                loadImageSlider();
                loadMobileZoom();
                splide.go(position);
            }, 200);
        },
        function () {
            ajaxModal = null;
        }
    );

}

function loadImageSlider()
{
    let popupBodyHeight = document.querySelector('#modal-image-zoom .zoom-popup-body').offsetHeight;
    splide = new Splide(`#modal-image-zoom .main-slider` , {
        pagination: false,
        type: 'fade',
        height: (popupBodyHeight-20)+"px",
        breakpoints : {
            1023: {
                height: ''
            }
        },
    });
    splide.on( 'inactive', function () {
        let element = document.querySelector('.zoomed');
        if (element) zoomImage(element, false);
    });
    let autoMoved = false;
    splide.on('drag', function () {
        autoMoved = false;
    });
    splide.on('moved', function (newIndex, prevIndex, destIndex) {
        if (!splide.options.drag && !autoMoved) {
            splide.go(prevIndex);
            autoMoved = true;
        }
    });

    let thumbnails = new Splide(`#modal-image-zoom .thumbnail-slider`, {
        direction: 'ttb',
        height: '20rem',
        fixedWidth: 60,
        fixedHeight: 60,
        gap: 10,
        rewind: true,
        pagination: false,
        cover: true,
        isNavigation: true,
        arrows: false,
        breakpoints: {
            1023: {
                direction: '',
                fixedWidth: 104,
                fixedHeight: 58,
                dragMinThreshold: {
                    mouse: 4,
                    touch: 10,
                },
            },
            640: {
                direction: '',
                fixedWidth : 66,
                fixedHeight: 38,
                dragMinThreshold: {
                    mouse: 4,
                    touch: 10,
                },
            },
            600: {
                direction: '',
                fixedWidth: 60,
                fixedHeight: 44,
                dragMinThreshold: {
                    mouse: 4,
                    touch: 10,
                },
            },

        },
    });

    splide.sync( thumbnails );
    splide.mount();
    thumbnails.mount();
}

export async function zoomImage(element, isZoomed)
{
    let image = element.querySelector('img');
    let parent = element.closest('.splide__slide');
    let slider = element.closest('.splide');
    let imageSize = await getImageSizeFromUrl(image.src);
    let width = imageSize.width;
    let height = imageSize.height;
    let margin = 50;
    let marginWidth = 20;
    let marginHeight = 10;
    let minWidth = slider.offsetWidth + margin;
    let minHeight = slider.offsetHeight + margin;

    if (width <= minWidth && height <= minHeight) element.style.backgroundSize = 'cover';
    if (isZoomed) {
        element.style.width = parent.offsetWidth - marginWidth + "px";
        element.style.height = parent.offsetHeight - marginHeight + "px";
        element.classList.add('zoomed');
        element.style.backgroundImage = `url('${image.src}')`;
        hideElement(image);
        element.onmousemove = (event) => {moveImage(event)};
    } else {
        element.classList.remove('zoomed');
        element.style.width = '';
        element.style.height = '';
        element.style.backgroundImage = '';
        setTimeout(function() {
            showElement(image);
        }, 15);
    }
}

function moveImage(e)
{
    let imageZoom = e.currentTarget;
    let offsetX = e.offsetX || null;
    let offsetY = e.offsetY || null;
    if (!offsetX || !offsetY) return;
    let x = offsetX/imageZoom.offsetWidth * 100;
    let y = offsetY/imageZoom.offsetHeight * 100;
    imageZoom.style.backgroundPosition = x + '% ' + y + '%';
}

function loadMobileZoom()
{
    if (isDesktop()) return;
    let images = document.querySelectorAll('#modal-image-zoom .main-slider .touch-zoom');
    let navigationTimeout;
    images.forEach(function(image) {
        new PinchZoom(image, {
            onZoomStart: function(object) {
                splide.options = {drag: false, arrows: false};
            },
            onZoomUpdate: function(object) {
                splide.options = {drag: false, arrows: false};
                clearTimeout(navigationTimeout);
                navigationTimeout = setTimeout(function() {
                    let allowNavigation = object.zoomFactor === 1;
                    splide.options = {drag: allowNavigation, arrows: allowNavigation};
                }, object.options.animationDuration+150);
            },
            draggableUnzoomed: false
        });
    });
}

export function removeZoomOfAllDesktopImages()
{
    let images = document.querySelectorAll('#modal-image-zoom .main-slider .desktop-zoom');
    images.forEach(function(image) {
        zoomImage(image, false);
    });
}