import {clickEvent, customEvent} from "../../../webpack/modules/events";
import {isDesktop, isIOS} from "../../../webpack/modules/devices";
import {clearIOSWindowFix} from "../../../webpack/modules-optimized/popup-image-zoom";
import {zoomImage} from "../../../webpack/modules-optimized/popup-image-zoom";

let isZoomed = false;

customEvent('hidden.bs.modal', '#modal-image-zoom', true,function () {
    if (!isDesktop() && isIOS()) clearIOSWindowFix();
});

clickEvent('#modal-image-zoom .main-slider .desktop-zoom', true, function (element) {
    if (!isDesktop()) return;
    isZoomed = !isZoomed;
    zoomImage(element, isZoomed);
});