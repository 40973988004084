export function openOffcanvasFromHtml(html, options = {}, removeOnHide = true)
{
    let offcanvas = createOffcanvasFromHtml(html, options);
    offcanvas.show();

    if (removeOnHide) {
        offcanvas._element.addEventListener('hidden.bs.offcanvas', function () {
            this.remove();
        });
    }
    offcanvas._element.addEventListener('hide.bs.offcanvas', function (event) {
        if (this.dataset.preventClosing == 'true') event.preventDefault();
    });
}

function createOffcanvasFromHtml(html, options = {})
{
    if (typeof html !== 'string') return null;
    let offcanvas = document.createElement('div');
    offcanvas.innerHTML = html;
    let offcanvasDiv = offcanvas.firstChild;
    document.body.appendChild(offcanvasDiv);

    return new Offcanvas(document.getElementById(offcanvasDiv.id), options);
}

export function showOffcanvas(id)
{
    document.querySelector('#'+id).dataset.preventClosing = 'false';
    let offcanvas = getOffcanvas(id);
    if (offcanvas) offcanvas.show();
}

export function hideOffcanvas(id)
{
    document.querySelector('#'+id).dataset.preventClosing = 'false';
    let offcanvas = getOffcanvas(id);
    if (offcanvas) offcanvas.hide();
}

export function getOffcanvas(id)
{
    let offcanvas = document.getElementById(id);

    return offcanvas ? Offcanvas.getOrCreateInstance(offcanvas) : null;
}

export function showLoader(offcanvas)
{
    if (offcanvas.querySelector('.offcanvas-loader')) return;
    let loader = getLoaderHtml();
    let body = offcanvas.querySelector('.offcanvas-body');
    if (body) body.insertAdjacentHTML('afterend', loader);
    else offcanvas.innerHTML += loader;
}

export function hideLoader(offcanvas)
{
    let loader = offcanvas.querySelector('.offcanvas-loader');
    if (loader) loader.remove();
}

function getLoaderHtml()
{
    return `
        <div class="offcanvas-loader d-flex bg-white bg-opacity-75 position-absolute top-0 w-100 h-100 align-items-center justify-content-center z-index-inherit">
            <div class="spinner-border"></div>
        </div>
    `;
}