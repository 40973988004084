import {changeEvent, clickEvent} from "../../../../webpack/modules/events";
import {
    addItem,
    hideLoader, loadModifyItemModal, modifyItem, removeItem,
    renderCart,
    showLoader,
    updateItemQuantity,
    updateItemsNumber
} from "../../../../webpack/modules-optimized/cart";
import {
    dataLayerAddProduct,
    ecAddProduct,
    ecSetAction,
    ga4TrackSimpleEvent,
    sendFacebookConversionsEvent
} from "../../../../webpack/modules/analytics";
import {
    fixPersonalizedSVG,
    getDataAddToCart,
    getDataToRemoveFromCart,
    getSvgPreviewImage,
    previewOption
} from "../../../../webpack/modules-optimized/product";
import {hideModal, hideModalLoader, showModalLoader} from "../../../../webpack/modules-optimized/modal";
import {slideDown} from "../../../../webpack/modules/element-visibility";

changeEvent('#cart .quantity', true, async function(element) {
    let item = element.closest('.cart-item');
    let quantity = element.value > 0 ? element.value : 1;

    showLoader();
    let oldQuantity = await updateItemQuantity(item.dataset.id, quantity);
    await renderCart();
    updateItemsNumber(true);

    if (oldQuantity) {
        if (quantity > oldQuantity) {
            ecSetAction('add');
            let differenceQuantity = quantity - oldQuantity;
            dataLayerAddProduct({
                reference: item.dataset.reference,
                name: item.dataset.intname || '',
                price: item.dataset.price,
                quantity: differenceQuantity
            });
            ecAddProduct(
                item.dataset.id,
                item.dataset.name,
                item.dataset.price,
                differenceQuantity,
                item.dataset.colorApa,
                item.dataset.size,
                item.dataset.mirrored
            );
        } else {
            ecSetAction('remove');
            let differenceQuantity = quantity - oldQuantity;
            dataLayerAddProduct({
                reference: item.dataset.reference,
                name: item.dataset.intname || '',
                price: item.dataset.price,
                quantity: differenceQuantity
            }, true);
        }
    }

    hideLoader();
});

clickEvent('#cart .cart-item-remove', true, async function(element) {
    let item = element.closest('.cart-item');
    let quantity = item.querySelector('.quantity');

    showLoader();
    await removeItem(item.dataset.id);
    await renderCart();
    updateItemsNumber(true);
    ecSetAction('remove');
    dataLayerAddProduct({
        reference: item.dataset.reference,
        name: item.dataset.intname || '',
        price: item.dataset.price,
        quantity: quantity.value || 1
    }, true);
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'remove_product',
            product_id: item.dataset.reference
        }
    );
});

clickEvent('#cart .cart-item-modify', true, async function(element) {
    let item = element.closest('.cart-item');

    showLoader();
    ga4TrackSimpleEvent(
        {
            event: 'checkout',
            step: 1,
            method: 'modify_product',
            product_id: item.dataset.ecref || ''
        }
    );
    await loadModifyItemModal(item.dataset.id);
    previewOptions();
    hideLoader();
});

changeEvent('#cart #priority-service', true, async function(element) {
    let id = element.value;
    let width = element.dataset.width;
    let height = element.dataset.height;
    let cartItem = element.dataset.cartItem;

    showLoader();
    if (element.checked) {
        let data = [];
        let options = [];
        options['width'] = width;
        options['height'] = height;
        data['productId'] = id;
        data['quantity'] = 1;
        data['isLocked'] = true;
        data['options'] = options;
        data['extraProducts'] = [];

        let response = await addItem(data);
        let items = response.items;
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'add_priority'
            }
        );

        if (window.fbq && items && items[0] && items[0].reference) {
            var payload = {
                content_type: 'product',
                content_name: items[0].productName || '',
                content_ids: [items[0].reference],
                contents: [{id: items[0].reference, quantity: 1}],
                value: items[0].value || 0,
                currency: items[0].currency || ''
            };
            fbq('track', 'AddToCart', payload);
            sendFacebookConversionsEvent('AddToCart', payload);
        }
    } else {
        await removeItem(cartItem);
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'remove_priority'
            }
        );
    }

    updateItemsNumber(true);
    await renderCart();
    hideLoader();
});

clickEvent('#cart .complement .add-to-cart', true, async function(element) {
    let complement = element.closest('.complement');
    let name = complement.dataset.name;
    let price = complement.dataset.price;
    let data = {
        productId: complement.dataset.id,
        quantity: 1,
        options: {
            width: complement.dataset.width,
            height: complement.dataset.height,
            device: complement.dataset.device,
            composition: complement.dataset.composition,
        }
    }
    let size = null;
    if (data.width !== '' && data.height !== '') size = data.options.width + ' x ' + data.options.height + ' cm';

    showLoader();
    let response = await addItem(data);
    let items = response.items;
    await renderCart();
    updateItemsNumber(true);
    ecAddProduct(data.productId, name, price, 1, null, size, 'normal');
    ecSetAction('add');

    if (window.fbq && items && items[0] && items[0].reference) {
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 1,
                method: 'add_complement',
                product_id: items[0].reference
            }
        );
        fbq('track', 'AddToCart', {
            content_type: 'product',
            content_name: items[0].productName || '',
            content_ids: [items[0].reference],
            contents: [{id: items[0].reference, quantity: 1}],
            value: items[0].value || 0,
            currency: items[0].currency || ''
        });
    }

    hideLoader();
});

clickEvent('#product-popup[data-scope="shopping-cart"] .btn-add-to-cart', true, async function(element) {
    let modal = element.closest('.modal');
    let container = element.closest('.product-scope');
    let customText = container.querySelector('textarea[name="customText"], input[name="customText"]');
    let color = container.querySelector('input[name="color"]:checked');
    let personalizedSvg = container.querySelector('input[name="personalizedSvg"]');
    let personalizedImagePreview = container.querySelector('input[name="personalizedImagePreview"]');

    showModalLoader(modal);

    if (customText && customText.value !== '' && personalizedSvg && personalizedImagePreview) {
        let svgResponse = await getSvgPreviewImage(container.dataset.productId, customText.value, color ? color.value : null);
        personalizedImagePreview.value = svgResponse.svgPreview;
        personalizedSvg.value = fixPersonalizedSVG(svgResponse.svg, container);
    }

    let dataAddToCart = getDataAddToCart(container);
    let dataToRemove = getDataToRemoveFromCart(container);

    dataToRemove.forEach((item) => removeItem(item.dataset.alreadyAdded));
    let response = await modifyItem(dataAddToCart);

    if (response.error) {
        let option = container.querySelector(`.product-options .product-option[data-type="${response.type}"]`);
        let message = option.querySelector(`.option-error`);
        message.innerHTML = response.message;
        slideDown(message);
        hideModalLoader(modal);
        option.scrollIntoView({block: "center", behavior: "smooth"});
    } else {
        hideModal('modal-product');
        showLoader();
        await renderCart();
        hideLoader();
    }
});

function previewOptions()
{
    let container = document.getElementById('modal-product');

    container.querySelectorAll('.product-option').forEach(function (option) {
        let previewElement = option.querySelector('.option-preview');
        previewOption(option, previewElement);
    });
}