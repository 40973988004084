import {clickEvent, dispatchCustomEvent, documentLoadEvent} from "../../../webpack/modules/events";
import {post} from "../../../webpack/modules/ajax";
import {slideDown, slideUp, slideUpBySelector} from "../../../webpack/modules/element-visibility";
import {isLogged} from "../../../webpack/modules/user";
import {getLoggedUserData} from "../../../webpack/modules-optimized/user";

documentLoadEvent(function() {
    updateLoginStatus();
});

async function updateLoginStatus()
{
    if (!isLogged()) {
        if (localStorage) {
            localStorage.removeItem('logged_user_name');
            localStorage.removeItem('logged_user_premium');
        }
        dispatchCustomEvent('userInfoLoaded');
        return;
    }

    let name = localStorage ? localStorage.getItem('logged_user_name') : null;
    let premium = localStorage ? localStorage.getItem('logged_user_premium') === 'true' : null;

    if (!name || !premium) {
        let data = await getLoggedUserData();
        if (!data) {
            if (localStorage) {
                localStorage.removeItem('logged_user_name');
                localStorage.removeItem('logged_user_premium');
            }
            dispatchCustomEvent('userInfoLoaded');
            return;
        }
        name = data.name;
        premium = data.premium;
    }
    let colorClass = premium ? 'text-gold' : 'text-green';

    let desktopElement = document.querySelector('#header-user, header .desktop .header-dropdown[data-type="user"]');
    if (desktopElement) {
        let column = desktopElement.parentElement;
        let text = desktopElement.querySelector('.user-name');
        let icon = desktopElement.querySelector('.fa-user, .tvi-user');

        column.classList.remove("user-register");
        column.classList.add("user-logged");
        text.innerHTML = name;
        icon.classList.remove('text-gray');
        icon.classList.add(colorClass);
    }

    let mobileElement = document.querySelector('#header-mobile-user, header .mobile');
    if (mobileElement) {
        let icon = mobileElement.querySelector('.fa-user, .tvi-user');

        icon.classList.remove('text-gray');
        icon.classList.add(colorClass);
    }

    if (localStorage) {
        localStorage.setItem('logged_user_name', name);
        localStorage.setItem('logged_user_premium', premium);
    }
    dispatchCustomEvent('userInfoLoaded');
}