import {clickEvent, keyupEvent} from "../../../webpack/modules/events";
import {slideDown, slideUp} from "../../../webpack/modules/element-visibility";
import {hideLoader, renderCart, showLoader} from "../../../webpack/modules-optimized/cart";
import {applyVoucher, removeVoucher} from "../../../webpack/modules-optimized/voucher";
import {ga4TrackSimpleEvent} from "../../../webpack/modules/analytics";

keyupEvent('#cart .voucher-code', true, function(element) {
    let code = element.value;
    let button = document.querySelector('#cart .voucher-apply');
    if (!button) return;

    if (code) {
        button.classList.remove('btn-outline-purple');
        button.classList.add('btn-purple');
    } else {
        button.classList.remove('btn-purple');
        button.classList.add('btn-outline-purple');
    }
});

clickEvent('#cart .voucher-apply', true, async function() {
    let code = document.querySelector('#cart .voucher-code').value;
    let errorElement = document.querySelector('#cart .voucher-error');
    let warningElement = document.querySelector('#cart .voucher-warning');

    if (!code) return;
    slideUp(errorElement);
    slideUp(warningElement);
    showLoader();

    let response = await applyVoucher(code);
    let warning = response.warning;
    let error = response.error;

    if (warning) {
        warningElement.innerHTML = warning;
        slideDown(warningElement);
        hideLoader();
        setTimeout(async function() {
            showLoader();
            await renderCart();
            hideLoader();
        }, 10000);
    } else if (error) {
        errorElement.innerHTML = error;
        slideDown(errorElement);
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 3,
                method: 'error_cupon'
            }
        );
        hideLoader();
    } else {
        ga4TrackSimpleEvent(
            {
                event: 'checkout',
                step: 3,
                method: 'add_cupon'
            }
        );
        await renderCart();
        hideLoader();
    }
});

clickEvent('#cart .voucher-remove', true, async function() {
    let errorElement = document.querySelector('#cart .voucher-error');
    let warningElement = document.querySelector('#cart .voucher-warning');

    slideUp(errorElement);
    slideUp(warningElement);
    showLoader();

    let response = await removeVoucher();
    let error = response.error;

    if (error) {
        errorElement.innerHTML = error;
        slideDown(errorElement);
        hideLoader();
    } else {
        await renderCart();
        hideLoader();
    }
});