import {post} from "../modules/ajax";
import Splide from "@splidejs/splide";
import Swiper from "swiper-v11";
import {Navigation} from 'swiper-v11/modules';
import {hoverElementEvent} from "../modules/events";
import {isDesktop} from "../modules/devices";
import {getLastViewedProducts} from "../modules/last-viewed-products";
import {adjustArrows} from "./recommenders";
import {isPremiumUser} from "./user";

let imageSliders = [];

export function updateList(type, filters, query, url, imageDisplay)
{
    return new Promise(resolve => {
        post(
            '/ajax/website/product-list/update',
            {
                type: type,
                filters: filters,
                q: query,
                url: url,
                imageDisplay: imageDisplay
            },
            function (data) {
                resolve(data);
            },
            function (error) {
                resolve([]);
            }
        );
    });
}

export function openFiltersSections(filters)
{
    filters.forEach(function(filter) {
        let section = filter.closest('section');
        let collapse = section.querySelector('.body.collapse');
        if (!collapse) return;
        collapse = Collapse.getOrCreateInstance(section.querySelector('.body.collapse'));
        collapse.show();
    });
}

export function openFiltersChild(filters)
{
    filters.forEach(function(filter) {
        let container = filter.closest('.filters-list');
        let isParent = filter.classList.contains('parent');
        let isChild = filter.classList.contains('child');

        if (isParent) {
            let child = container.querySelector(`input[name="filter"][data-parent="${filter.value}"]`);
            if (!child) return;
            let collapse = Collapse.getOrCreateInstance(child.closest('.child-categories'));
            collapse.show();
        } else if (isChild) {
            let collapse = Collapse.getOrCreateInstance(filter.closest('.child-categories'));
            collapse.show();
        }
    });
}

export function initCombinationSuggestions(combinationSuggestions)
{
    if (combinationSuggestions) {
        let type = 'loop';
        if (combinationSuggestions.querySelectorAll('.combination-suggestion').length === 1) type = 'slide';
        new Splide(combinationSuggestions, {
            type: type,
            perPage: 1,
            perMove: 1,
            padding: '5rem',
            lazyLoad: 'nearby',
            gap: 12,
            mediaQuery: 'min',
            breakpoints: {
                768: {
                    type: 'slide',
                    pagination: false,
                    perPage: 5,
                    perMove: 5,
                    padding: 0,
                }
            }
        }).mount();
    }
}

export function updateImageDisplay(type)
{
    let productsImages = document.querySelectorAll('.product-images');
    productsImages.forEach((productImages) => productImages.dataset.type = type);
}

export function displayProductProductListImages(container, speed = 300)
{
    let sliders = container.querySelectorAll('.swiper');
    //sliders.forEach((slider) => slider.swiper.slideTo(slider.swiper.slides.length - 1, speed));
    sliders.forEach((slider) => productListImagesMoveToSlideFade(slider.swiper, slider.swiper.slides.length - 1, speed));
}

export function displayRoomProductListImages(container, speed = 300)
{
    let sliders = container.querySelectorAll('.swiper');
    //sliders.forEach((slider) => slider.swiper.slideTo(0, speed));
    sliders.forEach((slider) => productListImagesMoveToSlideFade(slider.swiper, 0, speed));
}

let switchProductListImageTimers = {};
function productListImagesMoveToSlideFade(slider, position, speed = 100)
{
    let id = "id" + Math.random().toString(16).slice(2); //slider.el.id;
    let images = slider.el.querySelectorAll('picture');
    images.forEach((image) => image.classList.add('transitioning'));
    slider.slideTo(position, 0);
    clearTimeout(switchProductListImageTimers[id]);
    switchProductListImageTimers[id] = setTimeout(function() {
        images.forEach((image) => image.classList.remove('transitioning'));
    }, speed);
}

export function getFilters(container)
{
    let filters = {};

    container.querySelectorAll('input[name="filter"]:checked').forEach(function(filter) {
        let value = filter.value;
        if (value === '') return;
        let type = filter.dataset.type;

        if (type === 'newProducts') {
            filters[type] = value;
        } else {
            if (!filters.hasOwnProperty(type)) filters[type] = [];
            if (!filters[type].includes(value)) filters[type].push(value);
        }
    });

    return filters;
}

export function initProductImageSliders(container, imageDisplay)
{
    container.querySelectorAll('.product .swiper').forEach(function (slider) {
        let productImageDisplay = slider.dataset.imageDisplay;
        if (!productImageDisplay) productImageDisplay = imageDisplay;
        let swiper = new Swiper(slider, {
            modules: [Navigation],
            slidesPerView: 1,
            initialSlide: productImageDisplay === 'product' ? slider.querySelectorAll('.swiper-slide').length - 1 : 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            init: false
        });

        swiper.on('beforeInit', function () {
            slider.querySelectorAll('.swiper-slide').forEach((arrow) => arrow.classList.remove('d-none'));
            if (productImageDisplay === 'product') swiper.slideTo(swiper.slides.length - 1);
        });

        swiper.on('afterInit', function () {
            slider.querySelectorAll('.swiper-arrow').forEach((arrow) => arrow.classList.remove('d-none'));
        });

        swiper.init();
    });
}

export function updateExclusivePremiumProducts(container)
{
    let products = container.querySelectorAll('.product.exclusive-premium-product');
    let isPremium = isPremiumUser();

    if (!products || isPremium) return;

    products.forEach(function(product) {
        product.querySelector('.button-add-to-cart').classList.add('d-none');
        product.querySelector('.btn-premium-subscribe').classList.remove('d-none');
    });
}