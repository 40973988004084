import {changeEvent, dispatchCustomEvent} from "../../../../../webpack/modules/events";
import {updateButtonsPrice, updateCartSizesPrices} from "../../../../../webpack/modules-optimized/cart";
import {getSizesPricesByPack} from "../../../../../webpack/modules-optimized/product";

changeEvent('#product-popup input[name="pack"]', true, async function(element) {
    let option = element.closest('.product-option');
    let container = element.closest('.product-scope');
    let previewElement = option.querySelector('.option-preview');
    let productIndex = element.closest('.product-options').dataset.index;

    dispatchCustomEvent(
        'productPackChanged',
        {
            'option': option,
            'previewElement': previewElement
        }
    );

    updateCartSizesPrices(container);
    updateButtonsPrice(container);
});

function updateSizesPriceByPack(container, productIndex)
{
    return new Promise(async function (resolve) {
        let productId = container.dataset.productId;
        let pack = container.querySelector('input[name="pack"]:checked');

        if (!pack) return;

        let prices = await getSizesPricesByPack(productId, pack.value);

        prices.forEach(function (element) {
            let id = element.id;
            let price = element.price;
            let oldPrice = element.oldPrice;
            let labels = element.labels;
            let input = container.querySelector('.product-options[data-index="'+productIndex+'"] input[name="size"][value="'+id+'"]');
            let label = container.querySelector('label[for="'+input.id+'"]');

            input.dataset.price = price;
            label.querySelector('.size-price').innerHTML = price;
            if (oldPrice) label.querySelector('.size-old-price').innerHTML = oldPrice;
            label.querySelector('.size-labels').innerHTML = labels;
        });

        resolve();
    });
}