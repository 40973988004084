import {clickEvent, customDocumentEvent} from "../../../webpack/modules/events";
import {getModal} from "../../../webpack/modules-optimized/modal";
import {getCookie, setCookie} from "../../../webpack/modules-optimized/cookies";
import {trackLinkEvent} from "../../../webpack/modules/analytics";
import {showCountrySelectPopUp} from "../../../webpack/modules-optimized/country";

clickEvent('#modal-pop-up-country-select .option-go', true, function(element, e) {
    if (e.which === 3) return;
    let link = element.href;
    trackLinkEvent('i18nIdentifier', 'MoveTo', link.attr('title'), link);
});

clickEvent('#modal-pop-up-country-select .option-continue, #modal-pop-up-country-select .close', true, function() {
    let modal = getModal('modal-pop-up-country-select');
    setCookie('_tv_i18n_identified', 'y', 60);
    modal.hide();
});

customDocumentEvent('userCountryDataLoaded', function (event) {
    let cookieCountry = sessionStorage.getItem('_tv_user_cookie_country') === 'true';
    let userCountry = sessionStorage.getItem('_tv_user_country');
    let i18nIdentified = getCookie('_tv_i18n_identified');
    let cookieChoice = getCookie('_tv_cookie_choice');

    if (i18nIdentified) return;
    if (cookieChoice || !cookieCountry) showCountrySelectPopUp(userCountry);
});

customDocumentEvent('userCookiesSelected', function (event) {
    let userCountry = sessionStorage.getItem('_tv_user_country');
    let i18nIdentified = getCookie('_tv_i18n_identified');
    let cookieChoice = getCookie('_tv_cookie_choice');

    if (i18nIdentified) return;
    if (cookieChoice) showCountrySelectPopUp(userCountry);
});

