import {changeEvent, fireChangeEvent} from "../../../webpack/modules/events";
import {slideDown, slideUp} from "../../../webpack/modules/element-visibility";

changeEvent('input[name="gender"]', true, function(element) {
    let container = element.closest('.product-scope');
    let gender = element.value;
    let modelOption = container.querySelector('.product-option[data-type="model"]');
    let oldModels = modelOption.querySelector('.gender-models:not(.d-none)');
    let newModels = modelOption.querySelector('.gender-models[data-gender="'+gender+'"]');
    let modelDefault = newModels.querySelectorAll('input[name="model"]')[0];
    let optionBody = newModels.closest('.card-body');

    slideUp(optionBody, 200, function() {
        if (oldModels) oldModels.classList.add('d-none');
        newModels.classList.remove('d-none');
        modelDefault.checked = true;
        fireChangeEvent(modelDefault, true);
        slideDown(optionBody);
    });
});