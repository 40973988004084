import {changeEvent, dispatchCustomEvent, fireChangeEvent} from "../../../../../webpack/modules/events";
import {updateButtonsPrice} from "../../../../../webpack/modules-optimized/cart";
import {slideDown, slideUp} from "../../../../../webpack/modules-optimized/element-visibility";

changeEvent('#product-popup input[name="model"]', true, async function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let previewElement = option.querySelector('.option-preview');
    let sizeOption = container.querySelector(`.product-option[data-type="modelSize"]`);
    let oldSizes = sizeOption.querySelector('.model-sizes:not(.d-none)');
    let model = element.value;
    let newSizes = sizeOption.querySelector('.model-sizes[data-model="'+model+'"]');
    let optionBody = newSizes.closest('.card-body');
    let selectedSize = container.querySelector('input[name="modelSize"]:checked');
    let fixedMaterialColors = element.dataset.fixedMaterialColors ? element.dataset.fixedMaterialColors.split(',') : [];

    await slideUp(optionBody);
    if (oldSizes) oldSizes.classList.add('d-none');
    newSizes.classList.remove('d-none');
    slideDown(optionBody);

    if (selectedSize) {
        selectedSize.checked = false;
        fireChangeEvent(selectedSize, true);
    }

    container.querySelectorAll('input[name="materialColor"]').forEach(function(materialColor) {
        materialColor.disabled = false;
        if (!fixedMaterialColors.includes(materialColor.value)) {
            if (materialColor.checked) {
                materialColor.checked = false;
                fireChangeEvent(element, true);
            }
            materialColor.disabled = true;
        }
    });

    dispatchCustomEvent(
        'productModelChanged',
        {
            'option': option,
            'previewElement': previewElement
        }
    );
    updateButtonsPrice(container);
});