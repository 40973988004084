import {changeEvent, dispatchCustomEvent, keyupEvent} from "../../../../../webpack/modules/events";
import {updateButtonsPrice} from "../../../../../webpack/modules-optimized/cart";

keyupEvent('#product-popup input[name="customText"], #product-popup textarea[name="customText"]', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let customText = element.value;
    let customImage = option.querySelector('input[name="customImage"]');
    let customImageFromWeb = option.querySelector('input[name="customImageFromWeb"]');
    let previewElement = option.querySelector('.option-preview');
    let increment = option.querySelector('input[name="customPriceIncrement"]');

    dispatchCustomEvent(
        'productCustomTextChanged',
        {
            'option': option,
            'customText': customText,
            'customImage': customImage,
            'customImageFromWeb': customImageFromWeb,
            'previewElement': previewElement,
            'increment': increment
        }
    );
    if (increment.checked) updateButtonsPrice(container);
});

changeEvent('#product-popup input[name="customPriceIncrement"]', true, function(element) {
    let container = element.closest('.product-scope')
    let option = element.closest('.product-option');
    if (!option.querySelector('input[name="customText"], textarea[name="customText"]')) return;
    let customText = option.querySelector('input[name="customText"], textarea[name="customText"]');
    let previewElement = option.querySelector('.option-preview');
    if (!customText) return;

    dispatchCustomEvent(
        'productCustomTextIncrementChanged',
        {
            'option': option,
            'previewElement': previewElement,
            'customText': customText,
            'increment': element
        }
    );
    updateButtonsPrice(container);
});