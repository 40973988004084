import {customDocumentEvent} from "../../../webpack/modules/events";
import {previewOption} from "../../../webpack/modules-optimized/product";

customDocumentEvent('productCustomTextChanged', function(event) {
    let option = event.detail.option;
    let previewElement = event.detail.previewElement;
    let increment = event.detail.increment;
    let customText = event.detail.customText;
    let customImage = event.detail.customImage;
    let customImageFromWeb = event.detail.customImageFromWeb;

    previewOption(option, previewElement);
    if (increment) increment.checked = !(increment.dataset.defaultStatus !== 'checked' && customText.value === '' && !customImage && !customImageFromWeb);
});

customDocumentEvent('productCustomTextIncrementChanged', function(event) {
    let option = event.detail.option;
    let previewElement = event.detail.previewElement;
    let customText = event.detail.customText;
    let increment = event.detail.increment;

    if (!increment.checked) customText.value = '';
    previewOption(option, previewElement);
});