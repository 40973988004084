import {changeEvent, dispatchCustomEvent} from "../../../../../webpack/modules/events";
import {updateButtonsPrice} from "../../../../../webpack/modules-optimized/cart";

changeEvent('#product-popup input[name="support-color"]', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let previewElement = option.querySelector('.option-preview');

    dispatchCustomEvent(
        'productSupportColorChanged',
        {
            'option': option,
            'previewElement': previewElement
        }
    );
    updateButtonsPrice(container);
});
