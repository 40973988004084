import {post} from "../modules/ajax";

let ajaxVoucher = null;
export function applyVoucher(code)
{
    if (ajaxVoucher) ajaxVoucher.abort();

    return new Promise(resolve => {
        ajaxVoucher = post(
            '/ajax/website/voucher/apply',
            {code: code},
            function (data) {
                ajaxVoucher = null;
                resolve({'warning': data.voucherWarningMessage});
            },
            function(data) {
                ajaxVoucher = null;
                resolve({'error': data.voucherErrorMessage});
            }
        );
    });
}

let ajaxRemoveVoucher;
export function removeVoucher()
{
    if (ajaxRemoveVoucher) ajaxRemoveVoucher.abort();
    return new Promise(resolve => {
        ajaxRemoveVoucher = post(
            '/ajax/website/voucher/remove',
            {},
            function () {
                ajaxRemoveVoucher = null;
                resolve({});
            },
            function (data) {
                ajaxRemoveVoucher = null;
                resolve({'error': data.voucherErrorMessage});
            }
        );
    });
}