//JS LIBRARIES
global.Collapse = require('bootstrap/js/dist/collapse');
global.Offcanvas = require('bootstrap/js/dist/offcanvas');
global.Modal = require('bootstrap/js/dist/modal');
require('../../scripts-optimized/polyfills');
require('../../scripts/analytics-events');

//JS
require('../../scripts-optimized/Promotions/main');
require('../../scripts-optimized/Whatsapp/main');
require('../../scripts-optimized/MessageSOS/main');
require('../../scripts-optimized/Cookies/main');
require('../../scripts-optimized/Newsletter/main');
require('../../scripts-optimized/Country/Popup/main');
require('../../scripts-optimized/PopupSmarterClick/main');
require('../../scripts-optimized/ProductFinder/main');
require('../../scripts-optimized/Header/Messages/main');
require('../../scripts-optimized/Header/Searcher/main');
require ('../../scripts/User/access');
require('../../scripts-optimized/Header/Login/main');
require('../../scripts-optimized/Header/Cart/main');
require('../../scripts-optimized/Cart/General/main');
require('../../scripts-optimized/Cart/Item/General/main');
require('../../scripts-optimized/Cart/Item/Options/Size/main');
require('../../scripts-optimized/Cart/Item/Options/GlassReversed/main');
require('../../scripts-optimized/Cart/Item/Options/Pack/main');
require('../../scripts-optimized/Cart/Item/Options/Color/main');
require('../../scripts-optimized/Cart/Item/Options/CustomText/main');
require('../../scripts-optimized/Cart/Item/Options/CustomImage/main');
require('../../scripts-optimized/Cart/Item/Options/Tissue/main');
require('../../scripts-optimized/Cart/Item/Options/TissueDrop/main');
require('../../scripts-optimized/Cart/Item/Options/SupportColor/main');
require('../../scripts-optimized/Cart/Item/Options/Model/main');
require('../../scripts-optimized/Cart/Item/Options/MaterialColor/main');
require('../../scripts-optimized/Cart/Item/Options/MaterialCanvas/main');
require('../../scripts-optimized/Cart/Item/Options/ModelSize/main');
require('../../scripts-optimized/Cart/Item/ImageZoom/main');
require('../../scripts-optimized/Cart/Voucher/main');
require('../../scripts-optimized/Header/OuterMenu/main');
require('../../scripts-optimized/Header/Breadcrumb/main');
require('../../scripts-optimized/LeftMenu/main');
require('../../scripts-optimized/Product/Size/main');
require('../../scripts-optimized/Product/Color/main');
require('../../scripts-optimized/Product/Pack/main');
require('../../scripts-optimized/Product/CustomText/main');
require('../../scripts-optimized/Product/CustomImage/main');
require('../../scripts-optimized/Product/GlassReversed/main');
require('../../scripts-optimized/Product/Tissue/main');
require('../../scripts-optimized/Product/TissueDrop/main');
require('../../scripts-optimized/Product/ChainPosition/main');
require('../../scripts-optimized/Product/SupportColor/main');
require('../../scripts-optimized/Product/ModelGender/main');
require('../../scripts-optimized/Product/Model/main');
require('../../scripts-optimized/Product/ModelSize/main');
require('../../scripts-optimized/Product/MaterialColor/main');
require('../../scripts-optimized/Product/Complement/main');
require('../../scripts-optimized/Tools/Textarea/main');
require('../../scripts-optimized/Tools/ScrollTopButton/main');
require('../../scripts-optimized/Tools/PopupImageZoom/main');

require('../../scripts-optimized/ProductList/Results/General/main');
require('../../scripts-optimized/ProductList/Results/Reviews/main');
require('../../scripts-optimized/ProductList/Results/Filters/main');
require('../../scripts-optimized/ProductList/Results/Product/main');

require('../../scripts-optimized/Footer/Contact/main');
require('../../scripts-optimized/Footer/Newsletter/main');
require('../../scripts-optimized/Footer/Social/main');

require('../../scripts-optimized/Awin/main');