import {documentLoadEvent} from "../../../../webpack/modules/events";

documentLoadEvent(function() {
    copyFiltersToOffcanvas();
});

function copyFiltersToOffcanvas()
{
    let filtersDesktop = document.querySelector('#product-list-page #filters-desktop .filter-list');
    let filtersMobile = document.querySelector('#product-list-page #filters-mobile .filter-list');

    if (!filtersDesktop || !filtersMobile) return;

    filtersMobile.innerHTML = filtersDesktop.innerHTML;

    filtersMobile.querySelectorAll('.filter-type .ft-container').forEach(function(item) {
        let collapse = item.querySelector('.cbox');
        let button = item.querySelector('.button');
        if (collapse && button) {
            let object = Collapse.getOrCreateInstance(collapse);
            object.hide();
            button.setAttribute('aria-expanded', 'false');
            button.classList.add('collapsed');
        }
    });
}