import {customDocumentEvent, dispatchCustomEvent} from "../../../webpack/modules/events";
import {
    getProportionalHeight,
    previewOption,
    trackProductPageGa4Events
} from "../../../webpack/modules-optimized/product";
import {slideDown, slideUp} from "../../../webpack/modules-optimized/element-visibility";

customDocumentEvent('productSizeChanged', function(event) {
    let container = event.detail.container;
    let option = event.detail.option;
    let previewElement = event.detail.previewElement;
    let previewText = event.detail.previewText;

    if (!container || !option || !previewElement) return;

    previewOption(option, previewElement);
    trackProductPageGa4Events('Size', previewText);
});

customDocumentEvent('calculateCustomHeight', async function(event) {
    let productId = event.detail.productId;
    let container = event.detail.container;
    let option = event.detail.option;
    let width = parseFloat(event.detail.inputWidth.value);
    let factor = container.querySelector('input[name="factor"]') ? container.querySelector('input[name="factor"]').value : null;
    let errorElement = event.detail.errorElement;
    let minErrorSize = event.detail.minErrorSize;
    let maxSizeError = event.detail.maxSizeError;
    let minWidth = parseFloat(event.detail.inputWidth.dataset.min);
    let maxWidth = parseFloat(event.detail.inputWidth.dataset.max);

    await slideUp(errorElement);
    await slideUp(minErrorSize);
    await slideUp(maxSizeError);

    if (!width) {
        dispatchCustomEvent('customHeightErrorCalculate', {});
        return;
    }

    if (width < minWidth) {
        if (!option.classList.contains('has-error')) option.classList.add('has-error');
        slideDown(minErrorSize);
        dispatchCustomEvent('customHeightErrorCalculate', {});
        return;
    } else if (width > maxWidth) {
        if (!option.classList.contains('has-error')) option.classList.add('has-error');
        slideDown(maxSizeError);
        dispatchCustomEvent('customHeightErrorCalculate', {});
        return;
    }

    let response = await getProportionalHeight(productId, width, factor);

    if (response.error) {
        if (!option.classList.contains('has-error')) option.classList.add('has-error');
        if (response.message) {
            errorElement.innerHTML = response.message;
            slideDown(errorElement);
        }
        dispatchCustomEvent('customHeightErrorCalculate', {});
        return;
    }

    option.classList.remove('has-error');
    event.detail.inputHeight.innerHTML = response.height;
    event.detail.inputHeight.value = response.height;
    dispatchCustomEvent('customHeightCalculated', {});
});

customDocumentEvent('validateBothSize', async function(event)
{
    let option = event.detail.option;
    let width = parseFloat(event.detail.widthInput.value);
    let height = parseFloat(event.detail.heightInput.value);
    let minWidth = parseFloat(event.detail.widthInput.dataset.min);
    let maxWidth = parseFloat(event.detail.widthInput.dataset.max);
    let minHeight = parseFloat(event.detail.heightInput.dataset.min);
    let maxHeight = parseFloat(event.detail.heightInput.dataset.max);
    let minSizeError = event.detail.minSizeError;
    let maxSizeError = event.detail.maxSizeError;
    let errorWidth = event.detail.errorWidth;
    let errorHeight = event.detail.errorHeight;

    await slideUp(minSizeError);
    await slideUp(maxSizeError);
    await slideUp(errorWidth);
    await slideUp(errorHeight);

    if (!width || !height) {
        dispatchCustomEvent('errorValidateBothSize', {});
        return;
    } else if (width < minWidth || height < minHeight) {
        if (!option.classList.contains('has-error')) option.classList.add('has-error');
        slideDown(minSizeError);
        dispatchCustomEvent('errorValidateBothSize', {});
        if (width < minWidth) slideDown(errorWidth);
        else if (height < minHeight) slideDown(errorHeight);
        return;
    } else if (width > maxWidth || height > maxHeight) {
        if (!option.classList.contains('has-error')) option.classList.add('has-error');
        slideDown(maxSizeError);
        dispatchCustomEvent('errorValidateBothSize', {});
        if (width > minWidth) slideDown(errorWidth);
        else if (height > minHeight) slideDown(errorHeight);
        return;
    }

    option.classList.remove('has-error');
    dispatchCustomEvent('successValidateBothSize', {});
});