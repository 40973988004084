export function getImageSizeFromUrl(url)
{
    return new Promise(resolve => {
        let image = new Image();
        image.src = url;
        image.onload = function() {
            resolve({width: this.width, height: this.height});
        };
    });

}