import {changeEvent, dispatchCustomEvent} from "../../../../../webpack/modules/events";

changeEvent('#product-popup input[name="modelSize"]', true, function(element) {
    let option = element.closest('.product-option');
    let previewElement = option.querySelector('.option-preview');

    dispatchCustomEvent(
        'productModelSizeChanged',
        {
            'option': option,
            'previewElement': previewElement
        }
    );
});