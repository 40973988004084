import {
    acceptAllCookies,
    acceptAnalyticsCookies, acceptBasicCookies, acceptMarketingCookies, deleteAllCookies,
    showModalConfigCookies, getCookie, showModalCookies
} from "../../webpack/modules-optimized/cookies";
import {
    changeEvent,
    clickEvent,
    customDocumentEvent,
    customEvent,
    dispatchCustomEvent,
    fireClickEvent
} from "../../webpack/modules/events";
import {hideModal} from "../../webpack/modules-optimized/modal";
import {showNewsletterPopup} from "../../webpack/modules-optimized/newsletter";
import {hideOffcanvas} from "../../webpack/modules-optimized/offcanvas";

clickEvent('.accept-all-cookies', true, function() {
    acceptAllCookies(365);
    hideModal('modal-cookies');
    // hideOffcanvas('offcanvas-cookies');
    showNewsletterPopup();
    dispatchCustomEvent('userCookiesSelected', {});
});

clickEvent('.accept-basic-analytics-cookies', true, function() {
    deleteAllCookies();
    acceptAnalyticsCookies(7);
    acceptBasicCookies(7);
    // hideModal('modal-cookies');
    hideOffcanvas('offcanvas-cookies');
    showNewsletterPopup();
    dispatchCustomEvent('userCookiesSelected', {});
});

clickEvent('.decline-all-cookies', true, function() {
    acceptAnalyticsCookies(365);
    acceptBasicCookies(365);
    hideModal('modal-cookies');
    showNewsletterPopup();
    dispatchCustomEvent('userCookiesSelected', {});
});

clickEvent('.show-modal-config-cookies', true, function() {
    hideModal('modal-cookies');
    // hideOffcanvas('offcanvas-cookies');
    showModalConfigCookies();
});

changeEvent('#cookie-configuration-all', true, function(element) {
    let marketingCookies = document.querySelector('#cookie-configuration-marketing');

    if (element.checked) {
        marketingCookies.disabled = true;
        marketingCookies.checked = true;
    } else {
        marketingCookies.disabled = false;
    }
});

clickEvent("#save-configuration-cookies", true, function() {
    let analyticsCookies = true; //document.querySelector('#cookie-configuration-analytics').checked;
    let marketingCookies = document.querySelector('#cookie-configuration-marketing').checked;
    let basicCookies = document.querySelector('#cookie-configuration-basic').checked;

    if (marketingCookies && analyticsCookies && basicCookies) {
        acceptAllCookies(365, 'cookies_config');
    } else {
        //deleteAllCookies();
        if (analyticsCookies) acceptAnalyticsCookies(7);
        if (marketingCookies) acceptMarketingCookies(7);
        if (basicCookies) acceptBasicCookies(7);

        if (analyticsCookies && marketingCookies) {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_all'
            });
        } else if (analyticsCookies) {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_analytics_and_basic'
            });
        } else if (marketingCookies) {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_marketing_and_basic'
            });
        } else {
            dataLayer.push({
                event: 'cookies_config',
                method: 'accept_basic_only'
            });
        }
    }

    hideModal('modal-config-cookies');
    showNewsletterPopup();
    dispatchCustomEvent('userCookiesSelected', {});
});

customEvent('hide.bs.modal', '#modal-config-cookies', true, function() {
    if (!getCookie('_tv_cookie_choice')) {
        acceptAllCookies(30, 'cookies_config_close');
        showNewsletterPopup();
        dispatchCustomEvent('userCookiesSelected', {});
    }
});

clickEvent('#modal-cookies .signin-cookies', true, function () {
    acceptAllCookies(365, 'cookies_login');
    hideModal('modal-cookies');
    setTimeout(() => {
        let objectDesktop = document.querySelector('#header-user');
        let objectMobile = document.querySelector('#header-mobile-user');

        if (objectMobile) {
            if (objectMobile.offsetParent !== null) {
                location.replace(objectMobile.href);
            }
        }
        if (objectDesktop) {
            fireClickEvent(objectDesktop, false);
        }
    }, 50);
});

customDocumentEvent('userCountryDataLoaded', function (event) {
    let cookieChoice = getCookie('_tv_cookie_choice');
    let cookieCountry = sessionStorage.getItem('_tv_user_cookie_country') === 'true';

    if (!cookieChoice && cookieCountry) showModalCookies();
    else if (!cookieChoice) acceptAllCookies(365);
});