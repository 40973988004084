import {customDocumentEvent} from "../../../webpack/modules/events";
import {previewOption} from "../../../webpack/modules-optimized/product";

customDocumentEvent('productCustomImageChanged', function(event) {
    let option = event.detail.option;
    let customImageFromWeb = event.detail.customImageFromWeb;
    let previewElement = event.detail.previewElement;
    let increment = event.detail.increment;

    previewOption(option, previewElement);
    if (increment) increment.checked = true;
    if (customImageFromWeb) customImageFromWeb.checked = false;
});

customDocumentEvent('productCustomImageFromWebChanged', function(event) {
    let option = event.detail.option;
    let customImage = event.detail.customImage;
    let customImageFile = event.detail.customImageFile;
    let customImageDisplay = event.detail.customImageDisplay;
    let customImageFromWeb = event.detail.customImageFromWeb;
    let customText = event.detail.customText;
    let previewElement = event.detail.previewElement;
    let increment = event.detail.increment;

    customImageFile.value = null;
    customImage.value = '';
    customImageDisplay.value = '';

    if (customText && customText.value !== '') increment.checked = true;
    else if (increment) increment.checked = !customImageFromWeb.checked;
    previewOption(option, previewElement);
});

customDocumentEvent('productCustomImageIncrementChanged', function(event) {
    let option = event.detail.option;
    let previewElement = event.detail.previewElement;
    let increment = event.detail.increment;
    let customImage = event.detail.customImage;
    let customImageFile = event.detail.customImageFile;
    let customImageDisplay = event.detail.customImageDisplay;
    let customImageFromWeb = event.detail.customImageFromWeb;

    customImageFile.value = null;
    customImage.value = '';
    customImageDisplay.value = '';

    if (customImageFromWeb) customImageFromWeb.checked = !increment.checked;
    previewOption(option, previewElement);
});