import {clickEvent, documentLoadEvent} from "../../../../webpack/modules/events";
import {initProductImageSliders} from "../../../../webpack/modules-optimized/product-list";
import {getWishListItems} from "../../../../webpack/modules-optimized/wishlist";

documentLoadEvent(function() {
    let imageDisplay = document.querySelector('#product-list-page .image-display-selector:checked').value;
    fillWishListButtons();
    initProductImageSliders(document.querySelector('#product-list-page'), imageDisplay);
});


clickEvent('#product-list-page .image-slider .splide__arrow', true, function(element, event) {
    event.preventDefault();
});

function updateWishListButton(button, added)
{
    let icon = button.querySelector('i');
    if (added) {
        button.classList.add('added');
        icon.classList.remove('far');
        icon.classList.add('fas');
    } else {
        button.classList.remove('added');
        icon.classList.remove('fas');
        icon.classList.add('far');
    }
}

async function fillWishListButtons()
{
    let wishListItems = await getWishListItems();
    if (!wishListItems) return;
    document.querySelectorAll('#product-list-page .add-item-wish-list').forEach(function(button) {
        if (wishListItems.includes(parseInt(button.dataset.productId))) updateWishListButton(button, true);
    });
}