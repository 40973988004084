import {
    changeEvent,
    clickEvent, customDocumentEvent,
    dispatchCustomEvent,
    fireChangeEvent,
    keyupEvent
} from "../../../../../webpack/modules/events";
import {updateButtonsPrice} from "../../../../../webpack/modules-optimized/cart";
import {previewOption} from "../../../../../webpack/modules-optimized/product";

clickEvent('.on-click-check', true, function(element) {
    let input = document.querySelector(element.dataset.check);
    input.checked = true;
    fireChangeEvent(input, true);
});

changeEvent('#product-popup input[name="size"]', true, function(element) {
    let option = element.closest('.product-option');
    let container = element.closest('.product-scope');
    let previewElement = option.querySelector('.option-preview');

    dispatchCustomEvent(
        'productSizeChanged',
        {
            'option': option,
            'container': container,
            'previewElement': previewElement,
            'previewText': element.dataset.previewText
        }
    );
    updateButtonsPrice(container);
});

let timeoutCustomSizeInputs = null;
keyupEvent('#product-popup .custom-width', true, function(element) {
    let container = element.closest('.product-scope');
    let option = element.closest('.product-option');
    let inputWidth = element;
    let inputHeight = option.querySelector('.custom-height');
    let errorElement = option.querySelector('.alert.error');
    let minErrorSize = option.querySelector('.min-size-error');
    let maxErrorSize = option.querySelector('.max-size-error');

    clearTimeout(timeoutCustomSizeInputs);
    timeoutCustomSizeInputs = setTimeout(
        function() {
            dispatchCustomEvent(
                'calculateCustomHeight',
                {
                    'productId': container.dataset.productId,
                    'container': container,
                    'option': option,
                    'inputWidth': inputWidth,
                    'inputHeight': inputHeight,
                    'errorElement': errorElement,
                    'minErrorSize': minErrorSize,
                    'maxErrorSize': maxErrorSize
                }
            );
        },
        200
    );
});

let timeoutBothSizeInputs = null;
keyupEvent('#product-popup .both-width, #product-popup .both-height', true, function(element) {
    let option = element.closest('.product-option');
    let widthInput = option.querySelector('.both-width');
    let heightInput = option.querySelector('.both-height');
    let minSizeError = option.querySelector('.min-size-error');
    let maxSizeError = option.querySelector('.max-size-error');

    clearTimeout(timeoutBothSizeInputs);
    timeoutBothSizeInputs = setTimeout(
        function() {
            dispatchCustomEvent(
                'validateBothSize',
                {
                    'option': option,
                    'widthInput': widthInput,
                    'heightInput': heightInput,
                    'minSizeError': minSizeError,
                    'maxSizeError': maxSizeError
                }
            );
        },
        200
    );
});

customDocumentEvent('customHeightCalculated successValidateBothSize', function() {
    if (!document.querySelector('#modal-product')) return;
    let container = document.querySelector('#modal-product').querySelector('.product-scope');
    let option = container.querySelector('.product-option[data-type="size"]');
    let previewElement = option.querySelector('.option-preview');

    previewOption(option, previewElement);
    updateButtonsPrice(container);
});

customDocumentEvent('customHeightErrorCalculate errorValidateBothSize', function() {
    if (!document.querySelector('#modal-product')) return;
    let container = document.querySelector('#modal-product .product-scope');

    updateButtonsPrice(container);
});