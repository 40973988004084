import {post} from "../modules/ajax";
import {isLogged} from "../modules/user";

export function toggleWishlistItem(productId)
{
    return new Promise(resolve => {
        post(
            '/ajax/website/wish-list/add-product',
            {productId: productId},
            function (response) {
                resolve(response);
            },
            function (response) {
                resolve(response);
            }
        );
    });
}

export function getWishListItems()
{
    return new Promise(resolve => {
        if (isLogged()) {
            if (!localStorage.hasOwnProperty('wishlist') && isLogged()) {
                post(
                    '/ajax/website/wish-list/get-from-user',
                    {},
                    function(response) {
                        resolve(response.items);
                    }
                );
            } else if (localStorage.getItem('wishlist')) {
                resolve(JSON.parse(localStorage.getItem('wishlist')));
            } else {
                resolve(null);
            }
        } else {
            localStorage.removeItem('wishlist');
            resolve(null);
        }
    });
}

export async function saveWishListItem(productId)
{
    let wishListItems = await getWishListItems();
    wishListItems.push(productId);
    wishListItems = wishListItems.map((item) => parseInt(item));
    localStorage.setItem('wishlist', JSON.stringify(wishListItems));
}

export async function removeWishListItem(productId)
{
    let wishListItems = await getWishListItems();
    wishListItems = wishListItems.filter(item => parseInt(item) !== parseInt(productId));
    wishListItems = wishListItems.map((item) => parseInt(item));
    localStorage.setItem('wishlist', JSON.stringify(wishListItems));
}