import {post} from "../modules/ajax";
import {getCookie} from "./cookies";
import {openModalFromHtml} from "./modal";
import {ga4TrackSimpleEvent} from "../modules/analytics";

let ajaxSubscribe = null;
export function subscribe(email, name)
{
    if (ajaxSubscribe) ajaxSubscribe.abort();

    return new Promise(resolve => {
        ajaxSubscribe = post(
            '/ajax/website/newsletter/subscribe',
            {email: email, name: name || ''},
            function(data) {
                ajaxSubscribe = null;
                resolve(data.view);
            },
            function () {
                ajaxSubscribe = null;
                resolve('');
            }
        );
    });
}

let popupTimeout = null;
export function showNewsletterPopup()
{
    if (!canShowNewsletterPopup()) return;
    if (popupTimeout) clearTimeout(popupTimeout);
    setTimeout(
        function () {
            post(
                '/ajax/website/popup/newsletter',
                {},
                function(data) {
                    openModalFromHtml(data.modal);
                    ga4TrackSimpleEvent({
                        event: 'popup_newsletter',
                        method: 'view',
                    });
                }
            );
        }
    , 30000);
}

function canShowNewsletterPopup()
{
    let canShow = true;
    let skipPopup = document.getElementsByClassName('skip-newsletter-popup').length;
    if (!getCookie('_tv_cookie_choice')) return false;
    if (!getCookie('newsletter_loyalty_cookie') && !skipPopup) {
        if (typeof (Storage) !== 'undefined') {
            if (localStorage.getItem('tvn_shnewspop')) {
                let d1 = new Date();
                canShow = ((d1.getTime() - localStorage.getItem('tvn_shnewspop'))/1000/86400) > 30;
            } else {
                canShow = true;
            }
        } else {
            canShow = !(getCookie('tvn_shnewspop') === '1');
        }
    } else {
        canShow = false;
    }

    return canShow;
}